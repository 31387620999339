import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import '@/configs/i18n.ts';
import '@/configs/datadog.ts';

import '@vestiaire/design-system/dist/css/main.min.css';
import '@vestiaire/vc-vulcain-component-library/style.css';
import { getCookie, THEME_COOKIE_KEY } from '@/helpers/cookie';
import App from '@/layout/App';

import { DarkTheme, Theme } from '../components/Theme';

let theme = Theme;
try {
  const themeType = getCookie(THEME_COOKIE_KEY);
  theme = themeType === 'dark' || themeType === 'medium' ? DarkTheme : Theme;
} catch (e) {
  console.error(e);
}

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </StrictMode>
);
