import { Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormCheckbox from '@/components/Common/Form/FormCheckbox';
import { buildImageUrl, generateImgId, getPhotoInfo } from '@/helpers/image';
import { SelectedPhotoType } from './PhotoForm';
import PhotoItem from './PhotoItem';
import { DynamicFormValues, PhotoFormType } from '../DefectDetails';

interface DefectPhotoProps {
  onClick: (data: SelectedPhotoType) => void;
  selectedPhoto?: SelectedPhotoType;
  photos: PhotoFormType[];
}

const DefectPhotos = ({ selectedPhoto, onClick, photos }: DefectPhotoProps) => {
  const { t } = useTranslation();

  const { control } = useFormContext<DynamicFormValues>();

  const handleClick = (data: SelectedPhotoType) => () => {
    onClick(data);
  };

  return (
    <>
      <Stack mb={1.5} mt={-2.5}>
        <FormCheckbox
          data-testid="defect_visible_onPhoto_checkbox"
          control={control}
          name="isAppearedInPhoto"
          label={t('QC_V2.DEFECT.VISIBLE_DEFECT')}
          checkboxProps={{
            disabled: true,
          }}
        />
      </Stack>

      <Stack sx={{ flexDirection: 'row', gap: 2, flexWrap: 'wrap' }}>
        {photos.map((i, idx) => {
          if (!i.path) return null;

          const { imgType, imgName } = getPhotoInfo(i.path);

          const url = buildImageUrl({ path: imgName, type: imgType });

          const htmlId = generateImgId(imgType, idx);

          const isClicked = htmlId === selectedPhoto?.htmlId;

          return (
            <PhotoItem
              key={idx}
              imgUrl={url}
              isClicked={isClicked}
              onClick={handleClick({
                htmlId,
                type: imgType,
                index: idx,
                path: i.path,
              })}
            />
          );
        })}
      </Stack>
    </>
  );
};

export default DefectPhotos;
