import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

export default function AdditionalInfoSection({
  title,
  content,
}: {
  title: string | JSX.Element;
  content: string | React.ReactNode;
}) {
  return (
    <Container>
      {typeof title === 'string' ? (
        <Typography variant="overline" display="block" sx={{ mb: 1 }}>
          {title}
        </Typography>
      ) : (
        title
      )}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {content}
      </Box>
    </Container>
  );
}
