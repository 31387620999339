import { Box, Button, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useModal } from '@/context/Modal.context';
import { useQualityControlTool } from '@/context/Qualitycontrol.context';
import {
  defectDefaultValueMap,
  defectSubmissionMap,
  dynamicFormDefaultValues,
  validateDefectPhotos,
  checkHavingDimension,
} from '@/helpers/defect-form';
import { QC_DECISION_STATE } from '@/helpers/states';
import { useGetDimensionTypes } from '@/hooks/defect-details/use-get-dimension-types';
import { useSubmitDefect } from '@/hooks/defect-details/use-submit-defect';
import { useUpdateDefect } from '@/hooks/defect-details/use-update-defect';
import { useProduct } from '@/hooks/use-product';
import { useQcMode } from '@/hooks/use-qcMode';
import { CustomError } from '@/types/AxiosError';
import {
  DefectImportance,
  DefectOptionType,
  DefectRequest,
  FormDimension,
  Photo,
  SubmittedDefect,
} from '@/types/interfaces/defect-details';
import { FormSelectOption } from '@/types/interfaces/form';
import { QcInfoType } from '@/types/QcInfo';
import BasicInfoForm from './BasicInfoForms/BasicInfoForm';
import ConfirmModal from './ConfirmModal';
import PhotoForm from './PhotoForms/PhotoForm';
import CloseButton from '../Modal/CloseButton';

export type PhotoFormType = Partial<
  Photo & {
    id: number;
    checked: boolean;
    clicked: boolean;
    isHidden?: boolean;
  }
>;

export interface DynamicFormValues {
  defect?: DefectOptionType | null;
  comment?: string;
  defectImportance?: DefectImportance | null;
  defectQuantity?: DefectImportance | null;
  defectSize?: DefectImportance | null;
  color?: FormSelectOption | null;
  categoryBrandModel?: FormSelectOption | null;
  sizeType?: FormSelectOption | null;
  size?: FormSelectOption | null;
  material1?: FormSelectOption | null;
  material1Percent?: string;
  material2?: FormSelectOption | null;
  material2Percent?: string;
  weight?: string;
  dimensions?: FormDimension;
  pdpPhotos?: PhotoFormType[];
  uploadedPhotos?: PhotoFormType[];
  isAppearedInPhoto?: boolean;
}

interface DefectDetailProps {
  checkpointId: number;
  viewMode: boolean;
  submittedDefect?: SubmittedDefect;
  tabMode?: boolean;
  onTabChange?: (v: number) => void;
}

const DefectDetails = ({
  checkpointId,
  viewMode,
  submittedDefect,
  tabMode,
  onTabChange,
}: DefectDetailProps) => {
  const { setDefect, deleteDefect, updateDefect } = useQualityControlTool();

  const { setModalInfo } = useModal();
  const { isViewMode: isPageViewMode } = useQcMode();

  const { uuid, productId, qcData } = useProduct();

  const { mutate: submitDefectMutate, isPending: isSubmitting } =
    useSubmitDefect();

  const { mutate: updateDefectMutate, isPending: isUpdating } =
    useUpdateDefect();

  const { data: dimensions } = useGetDimensionTypes(productId);

  const methods = useForm<DynamicFormValues>({
    defaultValues: dynamicFormDefaultValues,
    shouldFocusError: true,
  });

  const {
    product: { images: pdpImages = [] },
  } = qcData as QcInfoType;

  useEffect(() => {
    if (!submittedDefect) return;

    const formValues = defectDefaultValueMap({
      data: submittedDefect,
      dimensions,
      pdpImages,
      isPageView: isPageViewMode,
    });

    methods.reset(formValues);
  }, [submittedDefect, dimensions, isPageViewMode, pdpImages]);

  const [stream, setStream] = useState<MediaStream>();

  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const videoRef = useRef<HTMLVideoElement>(null);
  const errorAnchorRef = useRef<HTMLDivElement>(null);

  const turnOffCamera = () => {
    if (!stream || !videoRef.current) return;

    const videoTracks = stream.getTracks();

    videoTracks.forEach((t) => {
      t.enabled = false;
      t.stop();
    });
    videoRef.current.srcObject = null;

    setStream(undefined);
  };

  const handleModalClose = () => {
    turnOffCamera();
    setModalInfo({
      modalType: '',
      modalInfo: {},
    });
  };

  const handleDelete = () => {
    if (!submittedDefect) return;
    deleteDefect({ id: submittedDefect.id });
    if (!tabMode) handleModalClose();
    if (onTabChange && tabMode) onTabChange(0);
  };

  const scrollToError = () =>
    errorAnchorRef.current?.scrollIntoView({ behavior: 'smooth' });

  const onError = (error: CustomError) => {
    const errData =
      error.response?.data?.['hydra:description'] || t('QC_V2.STH_WRONG');

    setErrorMessage(errData);
  };

  const onSuccess = (data: SubmittedDefect) => {
    if (data.isProhibited) {
      setModalInfo({
        modalType: 'QC_SUBMIT',
        modalInfo: {
          qcResult: QC_DECISION_STATE.REJECT,
          state: 'PROHIBITATION_MODAL',
        },
      });
    } else {
      handleModalClose();
    }
  };

  const handleSubmitDefect = (data: DefectRequest) => {
    submitDefectMutate(
      { uuid, data },
      {
        onSuccess: (data) => {
          setDefect({ data: [{ ...data, checkPointId: checkpointId }] });
          onSuccess(data);
        },
        onError: onError,
      }
    );
  };

  const handleUpdateDefect = (data: DefectRequest) => {
    if (!submittedDefect) return;
    updateDefectMutate(
      { uuid, data, defectId: submittedDefect.id },
      {
        onSuccess: (data) => {
          updateDefect({ ...data, checkPointId: checkpointId });
          onSuccess(data);
        },
        onError: onError,
      }
    );
  };

  const onSubmit = async (data: DynamicFormValues) => {
    const {
      defect,
      pdpPhotos = [],
      uploadedPhotos = [],
      dimensions = [],
    } = data;

    const hasAtLeastOneDimension = checkHavingDimension(
      methods.setError,
      t,
      dimensions,
      defect?.requiredFields
    );

    if (!hasAtLeastOneDimension) {
      scrollToError();
      return;
    }

    const photos: PhotoFormType[] = [...pdpPhotos, ...uploadedPhotos];

    const isPhotoValid = validateDefectPhotos(
      methods.setError,
      photos,
      t,
      defect?.requiredFields
    );

    if (!isPhotoValid) {
      scrollToError();
      return;
    }

    const reqData = defectSubmissionMap({ ...data, photos });

    if (viewMode) {
      handleUpdateDefect(reqData);
    } else {
      handleSubmitDefect(reqData);
    }
  };

  const submitBtnName = viewMode
    ? t('QC_V2.COMMON.UPDATE')
    : t('QC_V2.DEFECT.SUBMIT');

  const loadingName = viewMode
    ? `${t('QC_V2.COMMON.UPDATING')}...`
    : `${t('QC_V2.DEFECT.SUBMITING')}...`;

  return (
    <FormProvider {...methods}>
      <CloseButton onClick={handleModalClose} />

      <Box>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div ref={errorAnchorRef} />

          <BasicInfoForm />

          <PhotoForm
            videoRef={videoRef}
            turnOffCamera={turnOffCamera}
            setStream={setStream}
          />

          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={handleModalClose}>{t('QC_V2.MAIN.CANCEL')}</Button>

            {!isPageViewMode && (
              <Button type="submit" disabled={isSubmitting || isUpdating}>
                {isSubmitting || isUpdating ? loadingName : submitBtnName}
              </Button>
            )}

            {(isPageViewMode || viewMode) && (
              <Button onClick={() => setShowDeletePopup(true)}>
                {t('QC_V2.MAIN.DELETE')}
              </Button>
            )}
          </Box>
        </form>

        {showDeletePopup && (
          <ConfirmModal
            description={t('QC_V2.DEFECT.CONFIRM_DELETE')}
            onConfirm={() => {
              handleDelete();
              setShowDeletePopup(false);
            }}
            onCancel={() => setShowDeletePopup(false)}
          />
        )}

        {!!errorMessage && (
          <ConfirmModal
            title="Error"
            description={
              <Stack gap={0.5}>
                <Typography variant="body1">
                  {t('QC_V2.SUBMIT_FAILED')}
                </Typography>
                <Typography
                  variant="body2"
                  fontStyle="italic"
                >{`- ${errorMessage}`}</Typography>
              </Stack>
            }
            cancelBtnText={t('QC_V2.MAIN.OK')}
            onCancel={() => {
              setErrorMessage('');
            }}
          />
        )}
      </Box>
    </FormProvider>
  );
};

export default DefectDetails;
