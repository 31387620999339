import { Stack } from '@mui/material';
import { MouseEvent } from 'react';

interface MarkingPhotoProps {
  circles: JSX.Element[];
  photoUrl?: string;
  isClicked?: boolean;
  addCircle?: (e: MouseEvent<SVGSVGElement>) => void;
}

const MarkingPhoto = ({
  photoUrl,
  circles,
  isClicked,
  addCircle,
}: MarkingPhotoProps) => {
  return (
    <Stack
      alignItems="center"
      sx={{
        border: isClicked
          ? '2px solid rgba(30, 136, 229, 1)'
          : '1px solid rgba(224, 224, 224, 1)',
      }}
    >
      <svg
        viewBox="0 0 100 100"
        width="100%"
        height="100%"
        onClick={addCircle}
        style={{
          backgroundImage: `url(${photoUrl})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'contain',
        }}
      >
        {circles}
      </svg>
    </Stack>
  );
};

export default MarkingPhoto;
