import { parse, serialize, type CookieSerializeOptions } from 'cookie';

export const THEME_COOKIE_KEY = 'vc_bo_theme';

export const getCookieObject = () => {
  return parse(document.cookie);
};

export const setCookie = (
  key: string,
  value: string | null,
  options?: CookieSerializeOptions
) => {
  document.cookie = serialize(key, value ?? '', options);
  return getCookieObject();
};

export const getCookie = (key: string) => {
  return getCookieObject()[key];
};
