import { Box, CircularProgress } from '@mui/material';
import { BOX_SIZE } from './PhotoForms/PhotoForm';

const LoadingBox = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: BOX_SIZE,
        width: BOX_SIZE,
        border: '1px solid rgba(0, 0, 0, 0.12)',
      }}
    >
      <CircularProgress size={32} />
    </Box>
  );
};

export default LoadingBox;
