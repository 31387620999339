import { TFunction } from 'i18next';
import { WAREHOUSE_UNIT_TYPE } from '@/types/Warehouse';
import { WAREHOUSE_CONFIG } from './local-storage';

export enum LENGTH_FORMAT {
  CM = 'CM',
  IN = 'IN',
}
enum WEIGHT_FORMAT {
  GRAM = 'GRAM',
}

enum DECIMAL_FORMAT {
  COMMA = ',',
  DOT = '.',
}

// eslint-disable-next-line
export const DECIMAL_NUMBER_PATTERN_RGX = /^\d+((\.|\,)\d{0,2})?$/;

// eslint-disable-next-line
const DOT_OR_COMMA_RGX = /\.|\,/;

export type UnitConfigType = {
  dateFormat: string;
  lengthFormat: string;
  weightFormat: string;
  decimal: string;
  type: WAREHOUSE_UNIT_TYPE;
};

export const UNIT_CONFIG: Record<WAREHOUSE_UNIT_TYPE, UnitConfigType> = {
  FR: {
    type: WAREHOUSE_UNIT_TYPE.FR,
    dateFormat: 'DD/MM/YYYY',
    weightFormat: WEIGHT_FORMAT.GRAM,
    lengthFormat: LENGTH_FORMAT.CM,
    decimal: DECIMAL_FORMAT.COMMA,
  },
  US: {
    type: WAREHOUSE_UNIT_TYPE.US,
    dateFormat: 'MM/DD/YYYY',
    weightFormat: WEIGHT_FORMAT.GRAM,
    lengthFormat: LENGTH_FORMAT.IN,
    decimal: DECIMAL_FORMAT.DOT,
  },
};

export const getWarehouseConfig = (): UnitConfigType | null => {
  const config = localStorage.getItem(WAREHOUSE_CONFIG);
  return config ? JSON.parse(config) : null;
};

export const getWarehouseLocale = (): string => {
  const config = getWarehouseConfig();

  const locale =
    config && config.type === WAREHOUSE_UNIT_TYPE.FR ? 'en-GB' : 'en-US';

  return locale;
};

export const toWeightUnit = (label?: string) => {
  const config = getWarehouseConfig();

  const unit =
    config && config.weightFormat === WEIGHT_FORMAT.GRAM ? 'gr' : 'N/A';

  if (!label) return unit;

  return `${label} (${unit})`;
};

export const toLengthUnit = (label?: string) => {
  const config = getWarehouseConfig();

  const unit =
    config && config.lengthFormat === LENGTH_FORMAT.CM
      ? LENGTH_FORMAT.CM.toLowerCase()
      : LENGTH_FORMAT.IN.toLowerCase();

  if (!label) return unit;

  return `${label} (${unit})`;
};

export const getThousandSeparator = () => {
  const config = getWarehouseConfig();
  return config && config.type === WAREHOUSE_UNIT_TYPE.FR ? ' ' : ',';
};

export const toDecimalUnit = (value?: string) => {
  if (!value) return value;

  const config = getWarehouseConfig();

  const unit =
    config && config.decimal === DECIMAL_FORMAT.COMMA
      ? DECIMAL_FORMAT.COMMA
      : DECIMAL_FORMAT.DOT;

  const thousandSeparator = getThousandSeparator();

  return value
    .replace(DOT_OR_COMMA_RGX, unit)
    .replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
};

/*
  - parseFloat will not parse comma correctly so need replace comma with dot
  - Just use for number with two decimal places
*/
export const parseDecimal = (value?: string) => {
  if (!value) return 0;
  return parseFloat(value.replace(DOT_OR_COMMA_RGX, DECIMAL_FORMAT.DOT));
};

export const checkMaxValue =
  (maxNumber: number, t: TFunction<'translation', undefined>) =>
  (value?: string) => {
    if (!value) return undefined;
    return parseDecimal(value) > maxNumber
      ? t('QC_V2.DEFECT.MAX', { value: maxNumber })
      : undefined;
  };

export const checkMinValue =
  (minNumber: number, t: TFunction<'translation', undefined>) =>
  (value?: string) => {
    if (!value) return undefined;
    return parseDecimal(value) < minNumber
      ? t('QC_V2.DEFECT.MIN', { value: minNumber })
      : undefined;
  };
