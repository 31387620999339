import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from '@/context/Modal.context';
import { useQualityControlTool } from '@/context/Qualitycontrol.context';
import { useProduct } from '@/hooks/use-product';
import DefectDetails from './DefectDetails';
import QcModal from '../Modal/Modal';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { value, index, children, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const DefectModal = () => {
  const { defects } = useQualityControlTool();
  const {
    modal: { modalInfo },
  } = useModal();

  const { t } = useTranslation();

  const { product } = useProduct();

  const { checkpointId, viewMode, defectId, checkpointName } = (modalInfo ||
    {}) as {
    checkpointName: string;
    checkpointId: number;
    viewMode: boolean;
    defectId?: number;
  };

  const submittedDefects = useMemo(() => {
    if (!viewMode || !checkpointId || !defects.length) return [];

    if (defectId) {
      // Select one defect
      const selectedDefect = defects.find((d) => d.id === defectId);
      return selectedDefect ? [selectedDefect] : [];
    }
    // Show defect as tabs
    return defects.filter((d) => d.checkPointId === checkpointId);
  }, [viewMode, checkpointId, defects, defectId]);

  const [value, setValue] = useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const showTabs = !!submittedDefects?.length && submittedDefects.length > 1;

  const defect =
    !showTabs && submittedDefects?.length ? submittedDefects[0] : undefined;

  const title = viewMode
    ? t('QC_V2.DEFECT.DEFECT_DETAIL')
    : t('QC_V2.DEFECT.NEW_DEFECT');

  return (
    <QcModal
      open={true}
      id="defect-modal"
      header={title}
      dialogProps={{
        fullWidth: false,
        maxWidth: false,
        disableEscapeKeyDown: true,
        onClose: (_: any, reason?: string) => {
          if (reason === 'backdropClick') return;
        },
        sx: {
          '& .MuiDialogContent-root': {
            padding: 0,
          },
        },
      }}
      content={
        <Box sx={{ width: 692 }}>
          <Box
            sx={{
              p: '0.5rem 1.5rem',
              backgroundColor: 'textBlackColor.light',
            }}
          >
            <Typography variant="body1">
              {t('QC_V2.DEFECT.CHECKPOINT_NAME', {
                name: checkpointName || t('QC_V2.DEFECT.UNKNOWN'),
                interpolation: { escapeValue: false },
              })}
            </Typography>
          </Box>

          <Box sx={{ p: '1.25rem 1.5rem', pt: 0 }}>
            <Typography variant="body1" mt={1} mb={showTabs ? 1 : 'unset'}>
              {t('QC_V2.DEFECT.PRODUCT_CONDITION', {
                condition: product.condition || t('QC_V2.DEFECT.UNKNOWN'),
              })}
            </Typography>

            {showTabs ? (
              <>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs"
                >
                  {submittedDefects.map((d, idx) => (
                    <Tab
                      key={d.id}
                      label={`Defect ${idx + 1}`}
                      id={`tab-${idx}`}
                      aria-controls={`tabpanel-${idx}`}
                    />
                  ))}
                </Tabs>

                <Box>
                  {submittedDefects.map((d, idx) => {
                    return (
                      <TabPanel key={d.id} value={value} index={idx}>
                        <DefectDetails
                          checkpointId={checkpointId}
                          viewMode={viewMode}
                          submittedDefect={d}
                          tabMode={!!submittedDefects.length}
                          onTabChange={setValue}
                        />
                      </TabPanel>
                    );
                  })}
                </Box>
              </>
            ) : (
              <DefectDetails
                checkpointId={checkpointId}
                viewMode={viewMode}
                submittedDefect={defect}
              />
            )}
          </Box>
        </Box>
      }
    />
  );
};

export default DefectModal;
