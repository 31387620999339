import { useMutation } from '@tanstack/react-query';
import { SUBMIT_DEFECT } from '@/helpers/query-keys';
import { updateDefect } from '@/services/qc.service';
import { CustomError } from '@/types/AxiosError';
import {
  DefectRequest,
  SubmittedDefect,
} from '@/types/interfaces/defect-details';

type UpdateDefectType = { uuid: string; defectId: number; data: DefectRequest };

export const useUpdateDefect = () => {
  return useMutation<SubmittedDefect, CustomError, UpdateDefectType>({
    mutationKey: [SUBMIT_DEFECT],
    mutationFn: ({ uuid, data, defectId }: UpdateDefectType) =>
      updateDefect(uuid, defectId, data),
  });
};
