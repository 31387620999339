import { useContext } from 'react';
import { ProductContext } from '@/context/Product.context';
import { QcInfoType } from '@/types/QcInfo';

export const useProduct = () => {
  const { qcData, setQcData, reset, setProductId, productId, count } =
    useContext(ProductContext);

  const { product, state, uuid, record } = (qcData as QcInfoType) || {};

  return {
    qcData,
    setQcData,
    reset,
    setProductId,
    productId,
    count,
    product,
    state,
    uuid,
    record,
  };
};
