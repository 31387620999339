import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DateRangePicker from '@/components/QcListSearchBar/DateRangePicker';
import EmailSearch from '@/components/QcListSearchBar/EmailSearch';
import ProductIdSearch from '@/components/QcListSearchBar/ProductIdSearch';
import QcStatusSelect from '@/components/QcListSearchBar/QcStatusSelect';
import StandbyReasonSelect from '@/components/QcListSearchBar/StandbyReasonSelect';
import WarehouseSelect from '@/components/QcListSearchBar/WarehouseSelect';
import QcListTable from '@/components/QcListTable/QcListTable';
import { useQcList, QcListSearchParms } from '@/hooks/use-qcList';
import { QcStatesType } from '@/types/QcStatus';
import { StandbyReasonType } from '@/types/StandbyReasons';
import { WarehouseType } from '@/types/Warehouse';

interface CustomDivElement extends HTMLDivElement {
  getValue: () => {
    productId: string | null;
    email: string | null;
    warehouse: WarehouseType | null;
    qcStatus: QcStatesType[] | null;
    standbyReasons: StandbyReasonType[] | null;
    dateFrom: Date | null;
    dateTo: Date | null;
    count: number;
  };
}

export default function QcList() {
  const { t } = useTranslation();

  const productIdRef = useRef<CustomDivElement>(null);
  const emailRef = useRef<CustomDivElement>(null);
  const warehouseRef = useRef<CustomDivElement>(null);
  const qcStatusRef = useRef<CustomDivElement>(null);
  const standbyReasonRef = useRef<CustomDivElement>(null);
  const dateRangeRef = useRef<CustomDivElement>(null);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const today = dayjs();

  const defaultDateTo = today.endOf('D');
  const defaultDateFrom = defaultDateTo.subtract(7, 'day').startOf('D');

  const [listQueryParams, setListQueryParams] = useState<QcListSearchParms>({
    productId: null,
    agentEmail: '',
    warehouseId: null,
    qcStatus: [],
    standbyReasons: [],
    dateFrom: defaultDateFrom.toDate(),
    dateTo: defaultDateTo.toDate(),
    count,
  });

  const { qcList, isQcListLoading, isFetchQcListError } = useQcList({
    ...listQueryParams,
    page,
    rowsPerPage,
  });

  const getParams = () => {
    const { productId } =
      (productIdRef.current as CustomDivElement).getValue() || null;
    const { email } = (emailRef.current as CustomDivElement).getValue() || null;
    const { warehouse } =
      (warehouseRef.current as CustomDivElement).getValue() || null;
    const { qcStatus } =
      (qcStatusRef.current as CustomDivElement).getValue() || null;
    const { standbyReasons } =
      (standbyReasonRef.current as CustomDivElement).getValue() || null;
    const { dateFrom, dateTo } =
      (dateRangeRef.current as CustomDivElement).getValue() || null;

    const newCount = count + 1;
    const newQcStatus = (qcStatus || []).map((item) => item.status);
    const newStandbyReason = (standbyReasons || []).map((item) => item.id);

    if (
      productId !== listQueryParams.productId ||
      email !== listQueryParams.agentEmail ||
      warehouse !== listQueryParams.warehouseId ||
      newQcStatus !== listQueryParams.qcStatus ||
      newStandbyReason !== listQueryParams.standbyReasons ||
      dateFrom !== listQueryParams.dateFrom ||
      dateTo !== listQueryParams.dateTo
    ) {
      setPage(0);
    }
    setCount(newCount);
    setListQueryParams({
      productId,
      agentEmail: email,
      warehouseId: warehouse?.legacyId as number,
      qcStatus: (qcStatus || []).map((item) => item.status),
      standbyReasons: (standbyReasons || []).map((item) => item.id),
      dateFrom,
      dateTo,
      count: newCount,
    });
  };

  const onChangeRowsPerPage = (rowsPerPage: number) => {
    setRowsPerPage(rowsPerPage);
    setPage(0);
  };

  const onChangePage = (page: number) => {
    setPage(page);
  };

  return (
    <>
      <Box data-testid="qc_search_container" sx={{ p: 2 }}>
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            mb: '1rem',
            fontWeight: '500',
          }}
        >
          {t('QC_V2.QC_LST.SEARCH_BY')}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={2}>
            <ProductIdSearch ref={productIdRef} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <EmailSearch ref={emailRef} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <WarehouseSelect ref={warehouseRef} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <QcStatusSelect ref={qcStatusRef} />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <StandbyReasonSelect ref={standbyReasonRef} />
          </Grid>
          <DateRangePicker
            ref={dateRangeRef}
            dateFrom={defaultDateFrom}
            dateTo={defaultDateTo}
          />
        </Grid>
      </Box>
      <Box display="flex" flexDirection="row-reverse" marginRight="1rem">
        <Button
          variant="contained"
          sx={{
            backgroundColor: (theme) => theme.palette.textBlackColor.dark,
            padding: '0.8rem 2rem',
            fontWeight: '700',
            '&:hover': {
              backgroundColor: (theme) => theme.palette.textBlackColor.dark,
            },
          }}
          onClick={getParams}
        >
          {t('QC_V2.QC_LST.SEARCH')}
        </Button>
      </Box>
      <QcListTable
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        qcList={qcList || { data: [], totalItems: 0 }}
        isQcListLoading={isQcListLoading}
        isFetchQcListError={isFetchQcListError}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </>
  );
}
