import { MouseEvent } from 'react';
import { environments } from '@/environments';
import { PHOTO_TYPE } from '@/types/interfaces/defect-details';
import { IHydraError } from '@/types/interfaces/hydra';

export function buildImageUrl({
  path,
  type,
  width,
  height,
  quality,
}: {
  path: string;
  type?: PHOTO_TYPE;
  width?: number;
  height?: number;
  quality?: number;
}): string {
  // https://gitlab.vestiairecollective.com/images/image-projector
  const imageParams = {
    w: width,
    h: height,
    q: quality || 75,
    f: 'auto',
  };

  const paramsString = Object.entries(imageParams)
    .filter(([, value]) => value)
    .map(([key, value]) => `${key}=${value}`)
    .join(',');

  return path === ''
    ? ''
    : `${environments.imagesBaseUrl}/${environments.imageOptionsPrefix}/${paramsString}/${type || PHOTO_TYPE.PRODUIT}/${path}`;
}

export const generateImgId = (type: PHOTO_TYPE, index: number) =>
  `${type}-${index}`;

export const getSVGClickCoords = (e: MouseEvent<SVGSVGElement>) => {
  const target = e.target as SVGSVGElement;

  const point = target.createSVGPoint();

  // pass event coordinates
  point.x = e.clientX;
  point.y = e.clientY;

  // transform to SVG coordinates
  const svgP = point.matrixTransform(target.getScreenCTM()?.inverse());

  return [svgP.x, svgP.y];
};

export const buildFileUrl = (fileName: string) => {
  return `${environments.imagesBaseUrl}/invoices/${fileName}`;
};

export const getPhotoInfo = (path: string) => {
  const [imgType, imgName] = path.split('/');
  return { imgType: imgType as PHOTO_TYPE, imgName };
};

export const SIZE_IN_MB = 10;
export const FILE_MAX_SIZE = SIZE_IN_MB * 1024 * 1024; // 10MB

export const MAX_UPLOAD_FILE = 3;

export const getPhotoErrors = (
  errors: string[],
  errorData: IHydraError | null
) => {
  if (errorData) {
    const allMessages = [...errors, errorData['hydra:description']];
    return allMessages.filter(function (item, pos) {
      return allMessages.indexOf(item) == pos;
    });
  }
  return [];
};
