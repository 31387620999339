import { Theme } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/system';
import { ReactNode } from 'react';

const ListItemValue = styled(ListItemText)(() => ({
  '& .MuiListItemText-primary': {
    fontSize: '1rem',
    fontWeight: 500,
  },
  '& .MuiListItemText-secondary': {
    fontSize: '1rem',
    fontWeight: 400,
  },
  '& .MuiListItemText-secondary .MuiTypography-root': {
    color: 'inherit',
    fontSize: 'inherit',
  },
}));

export default function ProductInfoItem({
  primary,
  secondary,
  ...props
}: {
  secondary: ReactNode;
  primary: string | JSX.Element;
} & React.ComponentProps<typeof ListItemText>) {
  return (
    <ListItemValue
      primary={primary}
      secondaryTypographyProps={{ component: 'div' }}
      secondary={secondary}
      sx={(theme: Theme) => ({
        backgroundColor: theme.palette.text.disabled,
      })}
      {...props}
    />
  );
}
