import styled from '@emotion/styled';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import {
  List,
  ListItemText,
  ListItem,
  Checkbox,
  Theme,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { t } from 'i18next';
import { useMemo, useState } from 'react';
import { useModal } from '@/context/Modal.context';
import { useQualityControlTool } from '@/context/Qualitycontrol.context';
import { buildFileUrl } from '@/helpers/image';
import { useCheckPoints } from '@/hooks/use-checkpoints';
import { useProduct } from '@/hooks/use-product';
import { useQcMode } from '@/hooks/use-qcMode';
import useSkipCheckpoint from '@/hooks/use-skip-checkpoint';
import { CheckPointType } from '@/types/Checkpoint';
import DefectButton from './DefectButton';
import ConfirmModal from '../DefectDetailModal/ConfirmModal';

const MAX_DEFECTS = 5;

const TableHeadCell = styled(TableCell)`
  font-weight: bold;
  padding: 1rem;
`;

export const TableBodyCell = styled(TableCell, {
  shouldForwardProp: (propName) => propName !== 'isSkipped',
})<{ isSkipped?: boolean; theme?: Theme }>(({ isSkipped, theme }) => ({
  padding: '1rem',
  fontSize: '1rem',
  color: isSkipped ? theme?.palette.text.disabled : theme?.palette.text.primary,

  '& p, span': {
    color: isSkipped
      ? theme?.palette.text.disabled
      : theme?.palette.text.primary,
  },
}));

const ColumnWidths = ['35%', '45%', '10%', '10%'];

enum CurrentValueType {
  PHOTO = 'photo',
  TEXT = 'text',
}

export default function CheckPointList() {
  const { uuid, record } = useProduct();

  const { isViewMode } = useQcMode();

  const { mutate: skipCheckpoint } = useSkipCheckpoint();

  const { checkPointList: currentCheckpoints } = useCheckPoints(uuid);
  const { defects, deleteDefects } = useQualityControlTool();
  const { setModalInfo } = useModal();

  const [deletePopup, setDeletePopup] = useState<{
    state: boolean;
    checkpointId?: number;
    skipped?: boolean;
  }>();

  const checkpoints = useMemo(() => {
    let result = currentCheckpoints;

    if (isViewMode && record) {
      result = record.checkpointRecords?.map((r) => r.checkpoint);
    }

    return result
      ? result.sort((a, b) => a.sequenceNumber - b.sequenceNumber)
      : [];
  }, [isViewMode, record, currentCheckpoints]);

  const showDefectModal = (checkPoint: CheckPointType) => {
    if (checkPoint.id) {
      setModalInfo({
        modalType: 'DEFECT_CREATION',
        modalInfo: {
          checkpointName: checkPoint.name,
          checkpointId: checkPoint.id,
          viewMode: true,
        },
      });
    }
  };

  const getCurrentValue = (checkPoint: CheckPointType) => {
    const productPropertyPhotos = checkPoint.productPropertyPhotos || [];
    const productPropertyValue = (checkPoint.productPropertyValue || '').split(
      '\n'
    );

    const currentValue = [
      ...productPropertyPhotos.map((photo) => ({
        type: CurrentValueType.PHOTO,
        value: photo,
      })),
      ...productPropertyValue.map((value) => ({
        type: CurrentValueType.TEXT,
        value,
      })),
    ].filter((item) => item.value);

    return currentValue.length ? currentValue : null;
  };

  const handleCheck = (checkpointId: number, skipped: boolean) => {
    const defect = defects?.find((d) => d.checkPointId === checkpointId);

    if (defect) {
      setDeletePopup({ checkpointId, state: true, skipped });
      return;
    }

    skipCheckpoint({ uuid, id: checkpointId, skipped });
  };

  const handleDeleteDefects = (checkpointId: number, skipped: boolean) => {
    deleteDefects({ checkpointId });
    skipCheckpoint({ uuid, id: checkpointId, skipped });
  };

  return (
    <>
      <Table
        data-testid="checking_list_table"
        sx={{
          minWidth: 650,
          borderRight: '1px solid #e0e0e0',
        }}
        size="small"
        aria-label="check points table"
      >
        <TableHead>
          <TableRow>
            <TableHeadCell align="center">
              {t('QC_V2.MAIN.VISIBILITY')}
            </TableHeadCell>
            <TableHeadCell>{t('QC_V2.MAIN.CHECKPOINTS')}</TableHeadCell>
            <TableHeadCell>{t('QC_V2.MAIN.CURRENT_VALUE')}</TableHeadCell>
            <TableHeadCell align="center">
              {t('QC_V2.MAIN.DEFECT')}
            </TableHeadCell>
            <TableHeadCell align="center">
              {/* {t('QC_V2.MAIN.ACTIONS')} */}
            </TableHeadCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {checkpoints.map((checkPoint) => {
            const checkpointDefects = defects.filter((item) => {
              return item.checkPointId === checkPoint.id;
            });

            const isSkipped =
              checkPoint.isSkipped !== undefined
                ? checkPoint.isSkipped
                : checkPoint.defaultStatus === 'not_applicable';

            return (
              <TableRow
                key={checkPoint.id}
                sx={{
                  '& td:first-of-type': { fontWeight: 'bold' },
                }}
              >
                <TableBodyCell isSkipped={isSkipped}>
                  <Checkbox
                    disabled={isViewMode || !checkPoint.isAllowedToSkip}
                    sx={{
                      '&.Mui-checked': {
                        color: (theme) => theme.palette.text.secondary,
                      },
                    }}
                    checked={isSkipped}
                    onChange={(_, v) => handleCheck(checkPoint.id, v)}
                    checkedIcon={<IndeterminateCheckBoxIcon />}
                  />
                </TableBodyCell>

                <TableBodyCell
                  isSkipped={isSkipped}
                  sx={{ width: ColumnWidths[0], fontWeight: '500' }}
                >
                  {checkPoint.name || '-'}
                </TableBodyCell>

                <TableBodyCell
                  isSkipped={isSkipped}
                  sx={{ width: ColumnWidths[1] }}
                >
                  <List dense sx={{ listStyleType: 'disc' }}>
                    {
                      <>
                        {getCurrentValue(checkPoint)?.map((cv) => {
                          return (
                            <ListItem
                              key={cv.value}
                              sx={{
                                display: 'list-item',
                                position: 'relative',
                                left: '11px',
                                padding: '0',
                              }}
                            >
                              {cv.type === CurrentValueType.PHOTO ? (
                                <ListItemText
                                  primary={
                                    <Link
                                      href={buildFileUrl(cv.value)}
                                      target="_blank"
                                      sx={{ fontSize: 16 }}
                                    >
                                      {cv.value}
                                    </Link>
                                  }
                                >
                                  {cv.value}
                                </ListItemText>
                              ) : (
                                <ListItemText
                                  sx={{
                                    whiteSpace: 'pre-wrap',
                                    textAlign: 'left',
                                    fontSize: '1rem',
                                  }}
                                >
                                  <Typography variant="body1">
                                    {cv.value}
                                  </Typography>
                                </ListItemText>
                              )}
                            </ListItem>
                          );
                        }) || '-'}
                      </>
                    }
                  </List>
                </TableBodyCell>

                <TableBodyCell sx={{ width: ColumnWidths[2] }}>
                  {checkpointDefects.length ? (
                    <Button
                      data-testid="defect_button_number"
                      sx={(theme) => ({
                        color: theme.palette.error.main,
                        textDecoration: 'none',
                        fontWeight: '700',
                      })}
                      onClick={() => showDefectModal(checkPoint)}
                    >
                      {checkpointDefects.length}
                    </Button>
                  ) : (
                    <Button
                      data-testid="defect_button_dash"
                      sx={(theme) => ({
                        color: theme.palette.text.disabled,
                      })}
                    >
                      -
                    </Button>
                  )}
                </TableBodyCell>

                <TableBodyCell
                  sx={{ width: ColumnWidths[3], paddingLeft: '1rem' }}
                >
                  {isSkipped ? (
                    <></>
                  ) : (
                    <DefectButton
                      checkPoint={checkPoint}
                      disabled={defects.length >= MAX_DEFECTS}
                    />
                  )}
                </TableBodyCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      {!!deletePopup?.state && (
        <ConfirmModal
          description={t('QC_V2.MAIN.CONFIRM_SKIP')}
          onConfirm={() => {
            const { checkpointId, skipped } = deletePopup;

            if (checkpointId && skipped !== undefined) {
              handleDeleteDefects(checkpointId, skipped);
              setDeletePopup({ state: false });
            }
          }}
          onCancel={() => setDeletePopup({ state: false })}
        />
      )}
    </>
  );
}
