import { Grid } from '@mui/material';
import { Control, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormDecimalField from '@/components/Common/Form/FormDecimalField';
import { checkRequired } from '@/helpers/defect-form';
import { checkMaxValue, checkMinValue, parseDecimal } from '@/helpers/unit';
import { Defect } from '@/types/interfaces/defect-details';
import { FormSelectOption } from '@/types/interfaces/form';
import FormAutocomplete from '../../Common/Form/FormAutocomplete';
import { DynamicFormValues } from '../DefectDetails';

interface MaterialFormProps {
  control: Control<DynamicFormValues>;
  selectOptions: Record<keyof Defect, FormSelectOption[]>;
  disabled: boolean;
  isRequired?: boolean;
}

const MIN_VALUE = 1;
const MAX_VALUE = 100;

const MaterialForm = ({
  control,
  selectOptions,
  disabled,
  isRequired,
}: MaterialFormProps) => {
  const { t } = useTranslation();

  const { field: material1 } = useController({
    control,
    name: 'material1',
  });

  const { field: material2 } = useController({
    control,
    name: 'material2',
  });

  const { field: material1Per } = useController({
    control,
    name: 'material1Percent',
  });

  const { field: material2Per } = useController({
    control,
    name: 'material2Percent',
  });

  const required = isRequired ? t('QC_V2.DEFECT.REQUIRED') : undefined;

  const material1Required = material1Per.value
    ? t('QC_V2.DEFECT.REQUIRED')
    : undefined;

  const material2Required = material2Per.value
    ? t('QC_V2.DEFECT.REQUIRED')
    : undefined;

  const validateMaxValue = (
    value: string | undefined,
    formValue: DynamicFormValues
  ) => {
    if (!value) return;

    const materialPct1 = formValue.material1Percent
      ? parseDecimal(formValue.material1Percent)
      : 0;

    const materialPct2 = parseDecimal(value);

    const total = materialPct2 + materialPct1;

    if (formValue.material1Percent && total > MAX_VALUE) {
      return t('QC_V2.DEFECT.MATERIAL_PCT_MAX_VAL');
    }

    return materialPct2 > MAX_VALUE
      ? t('QC_V2.DEFECT.MAX', { value: MAX_VALUE })
      : undefined;
  };

  const isHidden = !selectOptions.materials.length;

  if (isHidden) return null;

  return (
    <Grid container spacing={2.5} item>
      <Grid item xs={3}>
        <FormAutocomplete
          data-testid="material1_combobox"
          control={control}
          name="material1"
          label={t('QC_V2.DEFECT.MATERIAL_1')}
          rules={{
            required: checkRequired(
              required || material1Required,
              selectOptions.materials
            ),
          }}
          autocompleteProps={{
            options: selectOptions.materials,
            disabled: disabled,
            isOptionEqualToValue: (option, value) =>
              option.value === value.value,
          }}
        />
      </Grid>

      <Grid item xs={3}>
        <FormDecimalField
          data-testid="material1_percentage_combobox"
          control={control}
          name="material1Percent"
          label={t('QC_V2.DEFECT.MATERIAL_1_PER')}
          value={material1Per.value || ''}
          rules={{
            required: material1.value ? t('QC_V2.DEFECT.REQUIRED') : undefined,
            validate: {
              max: checkMaxValue(MAX_VALUE, t),
              min: checkMinValue(MIN_VALUE, t),
            },
          }}
          onChange={(value) => {
            material1Per.onChange(value);
          }}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={3}>
        <FormAutocomplete
          data-testid="material2_combobox"
          control={control}
          name="material2"
          label={t('QC_V2.DEFECT.MATERIAL_2')}
          rules={{
            required: checkRequired(material2Required, selectOptions.materials),
          }}
          autocompleteProps={{
            options: selectOptions.materials,
            disabled: disabled,
            isOptionEqualToValue: (option, value) =>
              option.value === value.value,
          }}
        />
      </Grid>

      <Grid item xs={3}>
        <FormDecimalField
          data-testid="material2_percentage_combobox"
          control={control}
          name="material2Percent"
          label={t('QC_V2.DEFECT.MATERIAL_2_PER')}
          value={material2Per.value || ''}
          rules={{
            required: material2.value ? t('QC_V2.DEFECT.REQUIRED') : undefined,
            validate: {
              min: checkMinValue(MIN_VALUE, t),
              validateMaxValue,
            },
          }}
          onChange={(value) => {
            material2Per.onChange(value);
          }}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
};

export default MaterialForm;
