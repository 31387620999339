import { Box } from '@mui/material';
import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { SideMenu } from '@/components/Common/SideMenu/SideMenu';
import { RoutesMapping } from '@/helpers/routing';

export const DRAWER_WIDTH = 300;

export default function Layout({ children }: { children: React.ReactNode }) {
  const { pathname } = useLocation();
  const { id: productId } = useParams();

  const isQCPage = pathname === `${RoutesMapping.QC_TOOL}/${productId}`;

  const [open, setOpen] = useState(!isQCPage);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  return (
    <Box
      className="page-container"
      sx={(theme) => ({
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      })}
    >
      <SideMenu isQCPage={isQCPage} open={open} onOpen={handleDrawerOpen} />
      <Box sx={{ flexGrow: 1 }}>{children}</Box>
    </Box>
  );
}
