import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { useQualityControlTool } from '@/context/Qualitycontrol.context';
import { useQcMode } from '@/hooks/use-qcMode';

const AgentInternalComment = () => {
  const { t } = useTranslation();

  const { agentComment, setAgentComment } = useQualityControlTool();

  const { isViewMode } = useQcMode();

  return (
    <TextField
      data-testid="internal_comment_input_text"
      id="agentComment"
      label={t('QC_V2.MAIN.QC_INTERNAL_COMMENT')}
      placeholder={t('QC_V2.MAIN.COMMENT_PLACEHOLDER')}
      value={agentComment}
      multiline
      disabled={isViewMode}
      InputLabelProps={{
        shrink: true,
      }}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setAgentComment(event.target.value);
      }}
    />
  );
};

export default AgentInternalComment;
