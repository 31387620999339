import { datadogRum } from '@datadog/browser-rum';
import { useState, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ErrorModal from '@/components/Modal/ErrorModal';
import QcModal from '@/components/Modal/Modal';
import { useModal } from '@/context/Modal.context';
import { useQualityControlTool } from '@/context/Qualitycontrol.context';
import { RoutesMapping } from '@/helpers/routing';
import { PRODUCT_TOOK_OVER_ERROR, QC_DECISION_STATE } from '@/helpers/states';
import { parseDecimal } from '@/helpers/unit';
import { useCheckPoints } from '@/hooks/use-checkpoints';
import { useProduct } from '@/hooks/use-product';
import useUpdateQcState from '@/hooks/use-update-qc-state';
import { AddItemStatusType } from '@/types/AdditionalItem';
import { CheckpointStatueType } from '@/types/Checkpoint';
import { QcInfoType } from '@/types/QcInfo';
import { StandbyReasonType } from '@/types/StandbyReasons';
import KoModalContent from './KoModalContent';
import NegoModalContent from './NegoModalContent';
import OkModalContent from './OkModalContent';
import OnHoldModalContent from './OnHoldModalContent';
import ProhibitionKoContent from './ProhibitionKoContent';

export default function QcSubmitModal() {
  const {
    additionalItems,
    refurbishment,
    weight,
    hallmarks,
    agentComment,
    agentPublicComment,
    defects,
    qcPhotos,
  } = useQualityControlTool();
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [submissionError, setSubmissionError] = useState<string | null>(null);

  const { modal, setModalInfo } = useModal();
  const { mutate: updateQcState } = useUpdateQcState();

  const [isChildDialogOpen, setChildDialogOpen] = useState(false);

  const { qcData, setQcData } = useProduct();
  const uuid = (qcData as QcInfoType).uuid;

  const { checkPointList } = useCheckPoints(uuid);

  const navigate = useNavigate();
  const childRef = useRef<HTMLDivElement>(null);
  const {
    modalType,
    modalInfo: { qcResult, state },
  } = modal || {};

  const checkPointRecords = (checkPointList || []).map((checkPoint) => {
    const { id: curCheckpointId, isSkipped, defaultStatus } = checkPoint;
    const checkPointDefects = (defects || []).filter((item) => {
      return item.checkPointId === curCheckpointId;
    });

    const defectsIds = (checkPointDefects || []).map((defect) => defect.id);
    let status: CheckpointStatueType;
    if (defaultStatus === 'not_applicable' && isSkipped === undefined) {
      status = 'not_applicable';
    } else {
      status = isSkipped
        ? 'not_applicable'
        : defectsIds.length
          ? 'not_compliant'
          : 'compliant';
    }

    return {
      checkpointId: curCheckpointId,
      status,
      defectRecordIds: defectsIds,
    };
  });

  const getQcSubmitParams = () => {
    const standbyReasonUuid =
      qcResult === QC_DECISION_STATE.HOLD
        ? ((childRef.current as any)?.getValue()?.standbyReason
            ?.uuid as StandbyReasonType['uuid'])
        : null;

    const standbyComment =
      qcResult === QC_DECISION_STATE.HOLD
        ? (childRef.current as any)?.getValue()?.comment
        : null;

    const { internal: internalPhotos, public: publicPhotos } = qcPhotos;

    const record = {
      additionalItemRecords: (additionalItems || []).map((item) => {
        return {
          additionalItemUuid: item.uuid,
          status: 'added' as AddItemStatusType,
        };
      }),
      checkpointRecords: checkPointRecords,
      agentComment: agentComment || null,
      agentPublicComment: agentPublicComment || null,
      weight: parseDecimal(weight || '0') || null,
      hallmarks: hallmarks || null,
      isRefurbished: refurbishment,
      internalAdditionalPhotos: internalPhotos.length
        ? internalPhotos.map((p) => p.imagePath)
        : null,
      publicAdditionalPhotos: publicPhotos.length
        ? publicPhotos.map((p) => p.imagePath)
        : null,
    };

    return {
      standbyReasonUuid,
      standbyComment,
      record,
    };
  };

  const validateFields = () => {
    let validateResult = true;

    if (qcResult === QC_DECISION_STATE.HOLD) {
      if (!(childRef.current as any)?.validate()) {
        validateResult = false;
      }
    }

    return validateResult;
  };

  const onSubmitQcDecision = async () => {
    if (!validateFields()) {
      return null;
    }

    setIsSubmitting(true);
    datadogRum.addAction('QC decision being submitted', {
      ...getQcSubmitParams(),
    });

    updateQcState(
      {
        uuid,
        state: qcResult,
        ...getQcSubmitParams(),
      },
      {
        onSuccess: ({ data }) => {
          datadogRum.addAction(`Submit QC successfully`, {
            resData: data,
          });

          if (!data) return;

          setQcData(
            Object.assign({
              ...data,
              submitted: true,
            })
          );
          setIsSubmitting(false);
          setSubmissionError(null);
          setModalInfo({
            modalType: '',
            modalInfo: {},
          });

          navigate(RoutesMapping.HOME, { replace: true });
        },
        onError: (error) => {
          datadogRum.addAction('QC decision submission error', error);
          console.error(error);
          setIsSubmitting(false);

          const errorMsg =
            error.response?.data?.['hydra:description'] || t('QC_V2.STH_WRONG');
          setSubmissionError(errorMsg);

          datadogRum.addError(errorMsg);

          const isTookOver =
            error.response?.data?.referenceErrorCode ===
            PRODUCT_TOOK_OVER_ERROR;

          if (isTookOver) {
            // Reload the page will refetch the QC, at this point it will show took over message
            window.location.reload();
          } else setChildDialogOpen(true);
        },
      }
    );
  };

  const getQcModalContent = () => {
    switch (qcResult) {
      case QC_DECISION_STATE.ACCEPT:
        return <OkModalContent />;
      case QC_DECISION_STATE.REJECT:
        if (state === 'PROHIBITATION_MODAL') {
          return <ProhibitionKoContent />;
        }
        return <KoModalContent />;
      case QC_DECISION_STATE.HOLD:
        return <OnHoldModalContent ref={childRef} />;
      case QC_DECISION_STATE.NEGOTIATE:
        return <NegoModalContent />;
      default:
        return null;
    }
  };

  const modalContent = getQcModalContent();

  return (
    <>
      <QcModal
        open={!!(modalType && qcResult)}
        header={t('QC_V2.MAIN.CONFIRMATION')}
        content={modalContent}
        maxWidth="xs"
        id={`${modalType}  ${qcResult}`}
        actionButtons={{
          cancel: {
            text: t('QC_V2.MAIN.CANCEL'),
            onClick: () => {
              setModalInfo({
                modalType: '',
                modalInfo: {},
              });
            },
          },
          confirm: {
            text: t('QC_V2.DEFECT.SUBMIT'),
            onClick: onSubmitQcDecision,
            isSubmitting,
          },
        }}
      />
      {isChildDialogOpen && (
        <ErrorModal
          actionButton={{
            text: t('QC_V2.MAIN.OK'),
            onClick: () => {
              datadogRum.addAction('Error modal OK button clicked');
              setChildDialogOpen(false);
              setSubmissionError(null);
            },
          }}
          errorContent={submissionError}
        />
      )}
    </>
  );
}
