import { ChangeEvent, useCallback } from 'react';
import {
  DECIMAL_NUMBER_PATTERN_RGX,
  getThousandSeparator,
} from '@/helpers/unit';

const useDecimalField = (callback?: (v: string) => void) => {
  const thousandSeparator = getThousandSeparator();

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value.trim().replaceAll(thousandSeparator, '');

      if (value && !DECIMAL_NUMBER_PATTERN_RGX.test(value)) return;

      if (callback) callback(value);
    },
    [thousandSeparator, callback]
  );

  return {
    onChange,
  };
};

export default useDecimalField;
