// tslint:disable:object-literal-sort-keys
import { IEnvironments } from './model';
import { version } from '../../package.json';

export const environmentsTable: IEnvironments = {
  i18n: {
    fallback: 'en',
    locale: 'en',
    i18nCookie: 'vc_bo_lng',
  },
  dev: {
    cookieDomain: 'localhost',
    siteUrl: 'http://localhost:3000',
    loginUrl: 'https://backoffice.staging.vestiairecollective.com/',
    apiBaseUrl: 'https://papi.staging.vestiairecollective.com', // GLOO endpoint
    imagesBaseUrl: 'https://images.staging.vestiairecollective.com',
    invoiceBaseUrl: 'https://images.staging.vestiairecollective.com/invoices',
    legacyBoUrl: 'https://bo.staging.vestiairecollective.com/admin',
    atlasBaseUrl: 'https://apiv2.staging.vestiairecollective.com',
    frontUrl: 'https://<langPlaceholder>.staging.vestiairecollective.com',
    customerUri: '/membres/profil-<cIdPlaceholder>.shtml',
    negotiationUri: '/include/nego_v2.php?id_produit=<pIdPlaceholder>',
    nonCompliantUri:
      '/include/nego_v2.php?non_conforme=1&id_produit=<pIdPlaceholder>&new=1',
    qualityControlReportUri:
      '/admin.php?search=<pIdPlaceholder>&EZBO_page=scan_qualite_v2&section=1',
    productBoUri:
      '/admin.php?EZBO_page=affichproduit&liaisonchamp=id&liaisonvalue=<pIdPlaceholder>&EZBO_EXTRA=1&EZBO_NOSEARCH=1&EZBO_reinit=1',
    logLevel: 'debug',
    jwtName: 'access_token',
    imageOptionsPrefix: 'images/resized',
    menu: {
      appsLink: 'https://backoffice.staging.vestiairecollective.com',
      boLink: 'https://backoffice.staging.vestiairecollective.com',
      preferencesLink:
        'https://backoffice.staging.vestiairecollective.com/user/preferences',
    },
    fenx: 'https://www.staging.vestiairecollective.com',
    datadog: {
      enabled: false,
      testCookie: 'NO_RUM',
      config: {
        applicationId: 'a0b863fb-cfe0-46ce-9767-d1ed38b06688',
        clientToken: 'puba6ac67aab2686d3460b3d47e4e29c9d7',
        environment: 'dev',
        version,
      },
    },
  },
  teod: {
    cookieDomain: '.backoffice.staging.vestiairecollective.com',
    siteUrl: 'https://qc.backoffice.staging.vestiairecollective.com/',
    loginUrl: 'https://backoffice.staging.vestiairecollective.com/',
    apiBaseUrl: 'https://papi.staging.vestiairecollective.com', // API gateway
    imagesBaseUrl: 'https://images.staging.vestiairecollective.com',
    invoiceBaseUrl: 'https://images.staging.vestiairecollective.com/invoices',
    legacyBoUrl: 'https://bo.staging.vestiairecollective.com/admin',
    atlasBaseUrl: 'https://apiv2.staging.vestiairecollective.com',
    frontUrl: 'https://<langPlaceholder>.staging.vestiairecollective.com',
    customerUri: '/membres/profil-<cIdPlaceholder>.shtml',
    negotiationUri: '/include/nego_v2.php?id_produit=<pIdPlaceholder>',
    nonCompliantUri:
      '/include/nego_v2.php?non_conforme=1&id_produit=<pIdPlaceholder>&new=1',
    qualityControlReportUri:
      '/admin.php?search=<pIdPlaceholder>&EZBO_page=scan_qualite_v2&section=1',
    productBoUri:
      '/admin.php?EZBO_page=affichproduit&liaisonchamp=id&liaisonvalue=<pIdPlaceholder>&EZBO_EXTRA=1&EZBO_NOSEARCH=1&EZBO_reinit=1',
    logLevel: 'debug',
    jwtName: 'access_token',
    imageOptionsPrefix: 'images/resized',
    menu: {
      appsLink: 'https://backoffice.staging.vestiairecollective.com',
      boLink: 'https://backoffice.staging.vestiairecollective.com',
      preferencesLink:
        'https://backoffice.staging.vestiairecollective.com/user/preferences',
    },
    fenx: 'https://www.staging.vestiairecollective.com',
    datadog: {
      enabled: false,
      testCookie: 'NO_RUM',
      config: {
        applicationId: 'a0b863fb-cfe0-46ce-9767-d1ed38b06688',
        clientToken: 'puba6ac67aab2686d3460b3d47e4e29c9d7',
        environment: 'teod',
        version,
      },
    },
  },
  staging: {
    cookieDomain: '.backoffice.staging.vestiairecollective.com',
    siteUrl: 'https://qc.backoffice.staging.vestiairecollective.com/',
    loginUrl: 'https://backoffice.staging.vestiairecollective.com/',
    apiBaseUrl: 'https://papi.staging.vestiairecollective.com', // API gateway
    imagesBaseUrl: 'https://images.staging.vestiairecollective.com',
    invoiceBaseUrl: 'https://images.staging.vestiairecollective.com/invoices',
    legacyBoUrl: 'https://bo.staging.vestiairecollective.com/admin',
    atlasBaseUrl: 'https://apiv2.staging.vestiairecollective.com',
    frontUrl: 'https://<langPlaceholder>.staging.vestiairecollective.com',
    customerUri: '/membres/profil-<cIdPlaceholder>.shtml',
    negotiationUri: '/include/nego_v2.php?id_produit=<pIdPlaceholder>',
    nonCompliantUri:
      '/include/nego_v2.php?non_conforme=1&id_produit=<pIdPlaceholder>&new=1',
    qualityControlReportUri:
      '/admin.php?search=<pIdPlaceholder>&EZBO_page=scan_qualite_v2&section=1',
    productBoUri:
      '/admin.php?EZBO_page=affichproduit&liaisonchamp=id&liaisonvalue=<pIdPlaceholder>&EZBO_EXTRA=1&EZBO_NOSEARCH=1&EZBO_reinit=1',
    logLevel: 'debug',
    jwtName: 'access_token',
    imageOptionsPrefix: 'images/resized',
    menu: {
      appsLink: 'https://backoffice.staging.vestiairecollective.com',
      boLink: 'https://backoffice.staging.vestiairecollective.com',
      preferencesLink:
        'https://backoffice.staging.vestiairecollective.com/user/preferences',
    },
    fenx: 'https://www.staging.vestiairecollective.com',
    datadog: {
      enabled: true,
      testCookie: 'NO_RUM',
      config: {
        applicationId: 'a0b863fb-cfe0-46ce-9767-d1ed38b06688',
        clientToken: 'puba6ac67aab2686d3460b3d47e4e29c9d7',
        environment: 'staging',
        version,
      },
    },
  },
  production: {
    cookieDomain: '.backoffice.vestiairecollective.com',
    siteUrl: 'https://qc.backoffice.vestiairecollective.com/',
    loginUrl: 'https://backoffice.vestiairecollective.com/',
    apiBaseUrl: 'https://papi.vestiairecollective.com', // API gateway
    imagesBaseUrl: 'https://images.vestiairecollective.com',
    invoiceBaseUrl: 'https://images.vestiairecollective.com/invoices',
    legacyBoUrl: 'https://bo.vestiairecollective.com/admin',
    atlasBaseUrl: 'https://apiv2.vestiairecollective.com',
    frontUrl: 'https://<langPlaceholder>.vestiairecollective.com',
    customerUri: '/membres/profil-<cIdPlaceholder>.shtml',
    negotiationUri: '/include/nego_v2.php?id_produit=<pIdPlaceholder>',
    nonCompliantUri:
      '/include/nego_v2.php?non_conforme=1&id_produit=<pIdPlaceholder>&new=1',
    qualityControlReportUri:
      '/admin.php?search=<pIdPlaceholder>&EZBO_page=scan_qualite_v2&section=1',
    productBoUri:
      '/admin.php?EZBO_page=affichproduit&liaisonchamp=id&liaisonvalue=<pIdPlaceholder>&EZBO_EXTRA=1&EZBO_NOSEARCH=1&EZBO_reinit=1',
    logLevel: 'warn',
    jwtName: 'access_token',
    imageOptionsPrefix: 'images/resized',
    menu: {
      appsLink: 'https://backoffice.vestiairecollective.com',
      boLink: 'https://backoffice.vestiairecollective.com',
      preferencesLink:
        'https://backoffice.vestiairecollective.com/user/preferences',
    },
    fenx: 'https://www.vestiairecollective.com',
    datadog: {
      enabled: true,
      testCookie: 'NO_RUM',
      config: {
        applicationId: 'a0b863fb-cfe0-46ce-9767-d1ed38b06688',
        clientToken: 'puba6ac67aab2686d3460b3d47e4e29c9d7',
        environment: 'production',
        version,
      },
    },
  },
};

export type Env = Exclude<keyof IEnvironments, 'i18n'> | 'development';

function getEnvironment() {
  const env = process.env.NODE_ENV as Env;

  if (env === 'development') {
    return environmentsTable['dev'];
  }
  return environmentsTable[env];
}

export const environments = getEnvironment();
