import { TextField, TextFieldProps } from '@mui/material';
import { toDecimalUnit } from '@/helpers/unit';
import useDecimalField from '@/hooks/use-decimal-field';

type DecimalFieldType = {
  value: string;
  onChange: (v: string) => void;
} & Omit<TextFieldProps, 'onChange'>;

const DecimalField = (props: DecimalFieldType) => {
  const { value, onChange, disabled } = props || {};

  const { onChange: handleChange } = useDecimalField(onChange);

  return (
    <TextField
      {...props}
      value={toDecimalUnit(value)}
      onChange={handleChange}
      disabled={disabled}
    />
  );
};

export default DecimalField;
