import CloseIcon from '@mui/icons-material/Close';
import { Stack } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import ZoomButton from '../QcPanelModal/ZoomButton';

interface GalleryModalProps extends PropsWithChildren {
  zoomIn: () => void;
  zoomOut: () => void;
  onClose: () => void;
}

const GalleryModal = ({
  zoomIn,
  zoomOut,
  onClose,
  children,
}: GalleryModalProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth
      fullScreen
      open
      aria-labelledby="photo-gallery"
      scroll="paper"
      PaperProps={{
        sx: (theme) => ({
          backgroundColor: theme.palette.common.black,
        }),
      }}
    >
      <DialogTitle
        id="photo-gallery"
        sx={(theme) => ({
          color: theme.palette.common.white,
        })}
      >
        <Stack
          flexDirection="row"
          gap={3.5}
          width="100%"
          justifyContent="flex-end"
        >
          <Stack flexDirection="row" gap={1}>
            <ZoomButton btnName={t('QC_V2.MAIN.ZOOM_IN')} onClick={zoomIn} />
            <ZoomButton btnName={t('QC_V2.MAIN.ZOOM_OUT')} onClick={zoomOut} />
          </Stack>

          <FormControlLabel
            control={<CloseIcon />}
            label={t('QC_V2.MAIN.CLOSE')}
            sx={{ fontSize: 15 }}
            disableTypography
            onClick={onClose}
          />
        </Stack>
      </DialogTitle>

      <Stack height="90vh" flexDirection="row">
        {children}
      </Stack>
    </Dialog>
  );
};

export default GalleryModal;
