import { Stack, Typography } from '@mui/material';

interface PhotoErrorProps {
  errors: string[];
}

const PhotoErrors = ({ errors }: PhotoErrorProps) => {
  if (!errors.length) return null;
  return (
    <Stack gap={1}>
      {errors.map((e, idx) => (
        <Typography
          key={idx}
          variant="body2"
          color={(theme) => theme.palette.error.main}
        >
          - {e}
        </Typography>
      ))}
    </Stack>
  );
};

export default PhotoErrors;
