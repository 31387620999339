import { useRef } from 'react';
import { useProduct } from '@/hooks/use-product';
import ProductImages from './ProductImages';
import { ProductInfo } from './ProductInfo';
import './ProductInfo.scss';

export const GeneralInfo = () => {
  const { product } = useProduct();

  const containerRef = useRef<HTMLDivElement>(null);

  if (!product || !product.id) return null;

  return (
    <div ref={containerRef}>
      <ProductImages images={product.images} containerRef={containerRef} />
      <ProductInfo />
    </div>
  );
};
