import { Box } from '@mui/material';
import { PropsWithChildren, useEffect, useState } from 'react';
import MarkingCircle from './MarkingCircle';
import MarkingPhoto from './PhotoForms/MarkingPhoto';
import { BOX_SIZE } from './PhotoForms/PhotoForm';

interface PhotoBoxProps extends PropsWithChildren {
  imgUrl?: string;
  clicked?: boolean;
  width?: number;
  height?: number;
  annotations?: {
    x: number;
    y: number;
  }[];
  onClick?: () => void;
}

const PhotoBox = ({
  imgUrl,
  clicked,
  width,
  height,
  annotations,
  onClick,
  children,
}: PhotoBoxProps) => {
  const [circles, setCircles] = useState<JSX.Element[]>([]);

  /*
    Paint circles
  */
  useEffect(() => {
    if (!annotations?.length) {
      setCircles([]);
    } else {
      const prevCircles = annotations.map((a, idx) => {
        const id = new window.Date().getTime() + idx;

        const x = a.x * 100;
        const y = a.y * 100;

        return <MarkingCircle key={id} id={id.toString()} x={x} y={y} />;
      });

      if (prevCircles.length) {
        setCircles(prevCircles);
      }
    }
  }, [annotations]);

  return (
    <Box
      sx={{
        width: width || BOX_SIZE,
        height: height || BOX_SIZE,
        zIndex: 0,
        position: 'relative',
        cursor: onClick ? 'pointer' : 'unset',
      }}
      onClick={onClick}
      component="div"
    >
      <MarkingPhoto photoUrl={imgUrl} circles={circles} isClicked={clicked} />
      {children}
    </Box>
  );
};

export default PhotoBox;
