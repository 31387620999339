import { FC, ReactNode, useReducer, useState } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';
import { UploadedFileType } from '@/hooks/defect-details/use-upload-additional-photos';
import { UploadImageType } from '@/services/qc.service';
import { AdditionalItemType } from '@/types/AdditionalItem';
import { CheckPointType } from '@/types/Checkpoint';
import { SubmittedDefect } from '@/types/interfaces/defect-details';

interface SetHasIssuePayloadType {
  checkPoint: CheckPointType;
  hasIssue: boolean;
}

type SetDefectType = SubmittedDefect & { checkPointId: number };

export interface SetDefectPayloadType {
  data: SetDefectType[];
}
interface DeleteDefectPayloadType {
  id: number;
}

interface DeleteDefectsPayloadType {
  checkpointId: number;
}

type QCPhotoType = {
  internal: Array<UploadedFileType>;
  public: Array<UploadedFileType>;
};

interface QualityControlState {
  refurbishment: boolean;
  additionalItems: AdditionalItemType[];
  agentComment: string;
  agentPublicComment: string;
  weight: string;
  hallmarks: string;
  defects: SetDefectPayloadType['data'];
  qcPhotos: QCPhotoType;
}
interface QualityControlContextType extends QualityControlState {
  setAdditionalItems: (additionalItems: AdditionalItemType[]) => void;
  setRefurbishment: (refurbishment: boolean) => void;
  setWeight: (weight: string) => void;
  setAgentComment: (agentComment: string) => void;
  setAgentPublicComment: (comment: string) => void;
  setHallmarks: (hallmarks: string) => void;
  setDefect: ({ data }: SetDefectPayloadType) => void;
  deleteDefect: ({ id }: DeleteDefectPayloadType) => void;
  deleteDefects: ({ checkpointId }: DeleteDefectsPayloadType) => void;
  updateQCPhotos: (paths: UploadedFileType[], type: UploadImageType) => void;
  updateDefect: (data: SetDefectType) => void;
}

type ActionType =
  | {
      type: 'SET_HAS_ISSUE';
      payload: SetHasIssuePayloadType;
    }
  | {
      type: 'SET_DEFECT';
      payload: SetDefectPayloadType;
    }
  | {
      type: 'DELETE_DEFECT';
      payload: DeleteDefectPayloadType;
    }
  | {
      type: 'DELETE_DEFECTS';
      payload: DeleteDefectsPayloadType;
    }
  | {
      type: 'UPDATE_DEFECT';
      payload: SetDefectType;
    };

export enum MODAL_TYPES {
  IMAGE_GALLERY = 'IMAGE_GALLERY',
  DEFECT_CREATION = 'DEFECT_CREATION',
  DEFECT_VIEW = 'DEFECT_VIEW',
  QC_SUBMIT = 'QC_SUBMIT',
  ADDITIONAL_ITEMS = 'ADDITIONAL_ITEMS',
}

const initialState: QualityControlState = {
  additionalItems: [],
  agentComment: '',
  agentPublicComment: '',
  weight: '',
  hallmarks: '',
  refurbishment: false,
  defects: [],
  qcPhotos: {
    internal: [],
    public: [],
  },
};

const qualityControlReducer = (
  state: QualityControlState,
  action: ActionType
): QualityControlState => {
  const { payload } = action;

  switch (action.type) {
    case 'SET_DEFECT': {
      const { data } = payload as SetDefectPayloadType;

      // Merge the two arrays
      const mergedDefects = [...state.defects, ...data];

      // Remove duplicates based on the `id` property
      const uniqueDefects = mergedDefects.filter(
        (defect, index, self) =>
          index === self.findIndex((d) => d.id === defect.id)
      );

      return {
        ...state,
        defects: uniqueDefects,
      };
    }
    case 'DELETE_DEFECT': {
      const { id } = payload as DeleteDefectPayloadType;

      const defects = state.defects?.length
        ? state.defects.filter((d) => d.id !== id)
        : state.defects;

      return {
        ...state,
        defects: [...defects],
      };
    }
    case 'DELETE_DEFECTS': {
      const { checkpointId } = payload as DeleteDefectsPayloadType;

      const defects = state.defects?.length
        ? state.defects.filter((d) => d.checkPointId !== checkpointId)
        : state.defects;

      return {
        ...state,
        defects: [...defects],
      };
    }
    case 'UPDATE_DEFECT': {
      const data = payload as SetDefectType;

      const index = state.defects.findIndex((d) => d.id === data.id);

      if (index < 0) return { ...state };

      const defects = [...state.defects];

      defects.splice(index, 1, data);

      return {
        ...state,
        defects,
      };
    }
    default:
      return state;
  }
};

interface QualityControlProviderProps {
  children: ReactNode;
}

export const QualityControlContext = createContext<QualityControlContextType>(
  initialState as QualityControlContextType
);

export const QualityControlProvider: FC<QualityControlProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(qualityControlReducer, initialState);
  const [agentComment, setAgentComment] = useState<string>('');
  const [agentPublicComment, setAgentPublicComment] = useState<string>('');
  const [weight, setWeight] = useState<string>('');
  const [hallmarks, setHallmarks] = useState<string>('');
  const [additionalItems, setAdditionalItems] = useState<AdditionalItemType[]>(
    []
  );
  const [refurbishment, setRefurbishment] = useState<boolean>(false);

  const [qcPhotos, setQCPhotos] = useState<QCPhotoType>({
    internal: [],
    public: [],
  });

  const setDefect = ({ data }: SetDefectPayloadType) => {
    dispatch({
      type: 'SET_DEFECT',
      payload: {
        data,
      },
    });
  };

  const updateDefect = (data: SetDefectType) => {
    dispatch({
      type: 'UPDATE_DEFECT',
      payload: data,
    });
  };

  const deleteDefect = ({ id }: DeleteDefectPayloadType) => {
    dispatch({
      type: 'DELETE_DEFECT',
      payload: {
        id,
      },
    });
  };

  const deleteDefects = ({ checkpointId }: DeleteDefectsPayloadType) => {
    dispatch({
      type: 'DELETE_DEFECTS',
      payload: {
        checkpointId,
      },
    });
  };

  const updateQCPhotos = (files: UploadedFileType[], type: UploadImageType) => {
    setQCPhotos((prev) =>
      type === 'internal'
        ? { ...prev, internal: files }
        : { ...prev, public: files }
    );
  };

  const values = {
    ...state,
    setAdditionalItems,
    setAgentComment,
    setWeight,
    setHallmarks,
    agentComment,
    weight,
    hallmarks,
    additionalItems,
    refurbishment,
    setRefurbishment,
    setDefect,
    deleteDefect,
    deleteDefects,
    agentPublicComment,
    setAgentPublicComment,
    qcPhotos,
    updateQCPhotos,
    updateDefect,
  };

  return (
    <QualityControlContext.Provider value={values}>
      {children}
    </QualityControlContext.Provider>
  );
};

export const useQualityControlTool = () => {
  return {
    defects: useContextSelector(QualityControlContext, (v) => v.defects),
    setAdditionalItems: useContextSelector(
      QualityControlContext,
      (v) => v.setAdditionalItems
    ),
    additionalItems: useContextSelector(
      QualityControlContext,
      (v) => v.additionalItems
    ),
    agentComment: useContextSelector(
      QualityControlContext,
      (v) => v.agentComment
    ),
    weight: useContextSelector(QualityControlContext, (v) => v.weight),
    hallmarks: useContextSelector(QualityControlContext, (v) => v.hallmarks),
    setAgentComment: useContextSelector(
      QualityControlContext,
      (v) => v.setAgentComment
    ),
    setWeight: useContextSelector(QualityControlContext, (v) => v.setWeight),
    setHallmarks: useContextSelector(
      QualityControlContext,
      (v) => v.setHallmarks
    ),
    refurbishment: useContextSelector(
      QualityControlContext,
      (v) => v.refurbishment
    ),
    setRefurbishment: useContextSelector(
      QualityControlContext,
      (v) => v.setRefurbishment
    ),
    setDefect: useContextSelector(QualityControlContext, (v) => v.setDefect),
    deleteDefect: useContextSelector(
      QualityControlContext,
      (v) => v.deleteDefect
    ),
    deleteDefects: useContextSelector(
      QualityControlContext,
      (v) => v.deleteDefects
    ),
    agentPublicComment: useContextSelector(
      QualityControlContext,
      (v) => v.agentPublicComment
    ),
    setAgentPublicComment: useContextSelector(
      QualityControlContext,
      (v) => v.setAgentPublicComment
    ),
    qcPhotos: useContextSelector(QualityControlContext, (v) => v.qcPhotos),
    updateQCPhotos: useContextSelector(
      QualityControlContext,
      (v) => v.updateQCPhotos
    ),
    updateDefect: useContextSelector(
      QualityControlContext,
      (v) => v.updateDefect
    ),
  };
};
