import { amber, green, red } from '@mui/material/colors';
import {
  createTheme,
  alpha,
  getContrastRatio,
  PaletteColorOptions,
  SxProps,
  SimplePaletteColorOptions,
} from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    scheduledStatusColor: PaletteColorOptions;
    processingStatusColor: PaletteColorOptions;
    processedStatusColor: PaletteColorOptions;
    notificationColor: PaletteColorOptions;
    textBlackColor: SimplePaletteColorOptions;
    borderColor: SimplePaletteColorOptions;
  }

  interface PaletteOptions {
    scheduledStatusColor: PaletteColorOptions;
    processingStatusColor: PaletteColorOptions;
    processedStatusColor: PaletteColorOptions;
    notificationColor: PaletteColorOptions;
    textBlackColor: SimplePaletteColorOptions;
    borderColor: SimplePaletteColorOptions;
  }
}

// For some components we need main/light/dark/contrastText variant,
// Please provide the colorBase an colorMain for each new color
// FYI: convert rgba into hexadecimal
const scheduledColorBase = '#9c27b0cc';
const scheduledColorMain = alpha(scheduledColorBase, 0.12);

const processingColorBase = '#0288d1cc';
const processingColorMain = alpha(processingColorBase, 0.12);

const processedColorBase = '#2e7d32cc';
const processedColorMain = alpha(processedColorBase, 0.12);

const notificationColorBase = '#F5F5F5';
const notificationColorMain = alpha(notificationColorBase, 0.7);

const textBlackColorBase = '#000';
const textBlackColorMain = alpha(textBlackColorBase, 0.7);

export const Theme = createTheme({
  typography: {
    fontFamily: 'HelveticaNowText, sans-serif',
    allVariants: {
      color: textBlackColorBase,
    },
    h6: {
      fontSize: 20,
      lineHeight: '32px',
      fontWeight: 500,
    },
    body1: {
      fontSize: 16,
      lineHeight: '24px',
    },
    body2: {
      fontSize: 14,
    },
    subtitle1: {
      fontSize: 20,
      lineHeight: '32px',
      fontWeight: 700,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: 'HelveticaNowText, sans-serif',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => {
          const sx = ownerState.sx as SxProps & { backgroundColor: string };

          if (sx && sx.backgroundColor && ownerState.variant === 'contained') {
            return {
              ':hover': {
                backgroundColor: sx.backgroundColor,
                boxShadow: `0px 2px 4px -1px rgba(0, 0, 0, 0.2),
                 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
                 0px 1px 10px 0px rgba(0, 0, 0, 0.12)`,
              },
            };
          }
        },
      },
    },
  },
  palette: {
    primary: {
      main: 'rgba(33, 150, 243)',
      dark: 'rgba(238, 238, 238)',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    success: {
      main: green[500],
    },
    warning: {
      main: amber[700],
    },
    text: {
      secondary: 'rgba(0, 0, 0, 0.6)',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    textBlackColor: {
      main: textBlackColorMain,
      light: alpha(textBlackColorBase, 0.05),
      dark: alpha(textBlackColorBase, 0.9),
      contrastText:
        getContrastRatio(textBlackColorBase, '#FFF') > 4.5 ? '#FFF' : '#111',
    },
    background: {
      default: '#FFF',
      paper: '#FFF',
    },
    borderColor: {
      main: 'rgba(0, 0, 0, 0.5)', // Add a main color
      light: 'rgba(0, 0, 0, 0.12)',
      dark: 'rgba(30, 136, 229, 1)',
    },
    scheduledStatusColor: {
      main: scheduledColorMain,
      light: alpha(scheduledColorBase, 0.05),
      dark: alpha(scheduledColorBase, 0.9),
      contrastText:
        getContrastRatio(scheduledColorMain, '#000') > 4.5 ? '#000' : '#111',
    },
    processingStatusColor: {
      main: processingColorMain,
      light: alpha(processingColorBase, 0.05),
      dark: alpha(processingColorBase, 0.9),
      contrastText:
        getContrastRatio(processingColorMain, '#000') > 4.5 ? '#000' : '#111',
    },
    processedStatusColor: {
      main: processedColorMain,
      light: alpha(processedColorBase, 0.05),
      dark: alpha(processedColorBase, 0.9),
      contrastText:
        getContrastRatio(processedColorMain, '#000') > 4.5 ? '#000' : '#111',
    },
    notificationColor: {
      main: notificationColorBase,
      light: alpha(notificationColorBase, 0.05),
      dark: alpha(notificationColorBase, 0.9),
      contrastText:
        getContrastRatio(notificationColorMain, '#000') > 4.5 ? '#000' : '#111',
    },
  },
});

// Function to generate contrasting color
function generateContrastColor(color: string) {
  const contrastRatio = getContrastRatio(color, '#FFF');
  return contrastRatio > 4.5 ? '#FFF' : '#111';
}

// **Dark Theme Colors**
const darkBackground = '#121212'; // A dark gray background
const darkSurface = '#212121'; // Slightly lighter for surfaces
const darkTextPrimary = '#FFFFFF'; // White or very light gray for primary text
const darkTextSecondary = 'rgba(255, 255, 255, 0.7)'; // Lighter for secondary text

// **Component Colors (Dark Theme)**
const scheduledColorBaseDark = '#bb86fcc'; // Adjust for dark background
const scheduledColorMainDark = alpha(scheduledColorBaseDark, 0.2);

const processingColorBaseDark = '#81d4facc'; // Adjust for dark background
const processingColorMainDark = alpha(processingColorBaseDark, 0.2);

const processedColorBaseDark = '#66bb6acc'; // Adjust for dark background
const processedColorMainDark = alpha(processedColorBaseDark, 0.2);

const notificationColorBaseDark = '#252525'; // Darker gray for notifications
const notificationColorMainDark = alpha(notificationColorBaseDark, 0.7);

const textBlackColorBaseDark = '#FFF'; // White for black text
const textBlackColorMainDark = alpha(textBlackColorBaseDark, 0.7);

export const DarkTheme = createTheme({
  typography: {
    fontFamily: 'HelveticaNowText, sans-serif',
    allVariants: {
      color: darkTextPrimary, // Use light text on dark background
    },
    h6: {
      fontSize: 20,
      lineHeight: '32px',
      fontWeight: 500,
    },
    body1: {
      fontSize: 16,
      lineHeight: '24px',
    },
    body2: {
      fontSize: 14,
    },
    subtitle1: {
      fontSize: 20,
      lineHeight: '32px',
      fontWeight: 700,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: 'HelveticaNowText, sans-serif',
          backgroundColor: darkBackground, // Set the dark background color
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => {
          const sx = ownerState.sx as SxProps & { backgroundColor: string };

          if (sx && sx.backgroundColor && ownerState.variant === 'contained') {
            return {
              ':hover': {
                backgroundColor: sx.backgroundColor,
                boxShadow: `0px 2px 4px -1px rgba(255, 255, 255, 0.2),
                 0px 4px 5px 0px rgba(255, 255, 255, 0.14),
                 0px 1px 10px 0px rgba(255, 255, 255, 0.12)`,
                // White shadows
              },
            };
          }
        },
      },
    },
  },
  palette: {
    mode: 'dark', // Important: set the mode to 'dark'
    primary: {
      main: '#90caf9', // A lighter blue for dark mode
      light: '#e3f2fd',
      dark: darkBackground,
    },
    secondary: {
      main: '#80cbc4', // Adjust secondary color for dark mode
    },
    error: {
      main: red[400], // Adjust red for dark mode
    },
    success: {
      main: green[400], // Adjust green for dark mode
    },
    warning: {
      main: amber[300], // Adjust amber for dark mode
    },
    text: {
      primary: darkTextPrimary,
      secondary: darkTextSecondary,
      disabled: 'rgba(255, 255, 255, 0.38)',
    },
    background: {
      default: darkBackground,
      paper: darkSurface,
    },
    textBlackColor: {
      main: textBlackColorMainDark,
      light: alpha(textBlackColorBaseDark, 0.1),
      dark: alpha(textBlackColorBaseDark, 0.9),
      contrastText: generateContrastColor(textBlackColorMainDark),
    },
    borderColor: {
      main: 'rgba(255, 255, 255, 0.5)', // Adjust border color for dark mode
      light: 'rgba(255, 255, 255, 0.12)',
      dark: generateContrastColor('rgba(30, 136, 229, 1)'),
    },
    scheduledStatusColor: {
      main: scheduledColorMainDark,
      light: alpha(scheduledColorBaseDark, 0.05),
      dark: alpha(scheduledColorBaseDark, 0.9),
      contrastText: generateContrastColor(scheduledColorMainDark),
    },
    processingStatusColor: {
      main: processingColorMainDark,
      light: alpha(processingColorBaseDark, 0.05),
      dark: alpha(processingColorBaseDark, 0.9),
      contrastText: generateContrastColor(processingColorMainDark),
    },
    processedStatusColor: {
      main: processedColorMainDark,
      light: alpha(processedColorBaseDark, 0.05),
      dark: alpha(processedColorBaseDark, 0.9),
      contrastText: generateContrastColor(processedColorMainDark),
    },
    notificationColor: {
      main: notificationColorBaseDark,
      light: alpha(notificationColorBaseDark, 0.05),
      dark: alpha(notificationColorBaseDark, 0.9),
      contrastText: generateContrastColor(notificationColorMainDark),
    },
  },
});
