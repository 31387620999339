import ArrowUpwardOutlined from '@mui/icons-material/ArrowUpwardOutlined';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Button from './Button';

export interface UploadButtonProps {
  name: string;
  disabled?: boolean;
  onUpload?: (e: ChangeEvent<HTMLInputElement>) => void;
  iconButton?: JSX.Element;
}

const UploadButton = ({
  name,
  onUpload,
  disabled,
  iconButton,
}: UploadButtonProps) => {
  const { t } = useTranslation();

  return (
    <label htmlFor={name} style={{ display: 'inline-block' }}>
      <input
        style={{ display: 'none' }}
        id={name}
        name={name}
        type="file"
        accept="image/*"
        multiple
        onChange={onUpload}
        disabled={disabled}
      />
      {iconButton || (
        <Button
          name={t('QC_V2.DEFECT.UPLOAD_A_PHOTO')}
          icon={<ArrowUpwardOutlined />}
          props={{ disabled: disabled }}
        />
      )}
      {''}
    </label>
  );
};

export default UploadButton;
