import { Box, Stack } from '@mui/material';
import { VulcainHeader } from '@vestiaire/vc-vulcain-component-library';
import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { Error } from '@/components/Common/Error/Error';
import { useAuthContext } from '@/context/Authentication.context';
import { environments } from '@/environments';
import { useAgentInfo } from '@/hooks/use-agent';
import Logo from '@/layout/Logo';

const LoginContainer: FC = () => {
  const { isAuthenticated, user, accessToken, logout } = useAuthContext();
  const { agent } = useAgentInfo();

  const { t } = useTranslation();

  return (
    <>
      <Stack
        flexDirection="row"
        gap={1}
        alignItems="center"
        position="relative"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          position: 'sticky',
          top: 0,
          color: (theme) => theme.palette.text.primary,
          '& .vc-header': {
            background: (theme) => theme.palette.background.default,
            color: (theme) => theme.palette.text.primary,
          },
          '& .vc-btn--ghost': {
            color: (theme) => theme.palette.text.primary,
            borderColor: 'white',
          },
          '& .vc-dropdown__menu--items': {
            background: (theme) => theme.palette.background.default,
            color: (theme) => theme.palette.text.primary,
          },
        }}
      >
        <Box sx={{ position: 'absolute', zIndex: 900, left: 16 }}>
          <Logo />
        </Box>

        <Stack flex="1 1 auto">
          <VulcainHeader
            title={{
              title: t('QC_V2.TOOL_NAME'),
              link: '/',
            }}
            onLogout={() => {
              logout();
            }}
            menu={{
              appsLink: environments.menu.appsLink,
              loginTitle: 'Login',
              logoutTitle: 'Logout',
              preferencesLink: environments.menu.preferencesLink,
            }}
            onLogin={() => {
              window.location.href = environments.loginUrl;
            }}
            user={isAuthenticated && user}
          />
        </Stack>
      </Stack>

      {(!isAuthenticated ||
        !accessToken ||
        (agent && agent?.email !== user.email)) && (
        <Box pt={4}>
          <Error code={403} />
        </Box>
      )}
    </>
  );
};

export default LoginContainer;
