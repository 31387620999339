import { Box, Grid } from '@mui/material';
import { Control, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ErrorMessage from '@/components/Common/Form/ErrorMessage';
import FormDecimalField from '@/components/Common/Form/FormDecimalField';
import { checkMaxValue, parseDecimal, toLengthUnit } from '@/helpers/unit';
import { useGetDimensionTypes } from '@/hooks/defect-details/use-get-dimension-types';
import { useProduct } from '@/hooks/use-product';
import { DynamicFormValues } from '../DefectDetails';

interface DimensionFormProps {
  control: Control<DynamicFormValues>;
  disabled: boolean;
  isRequired?: boolean;
}

const DimensionForm = ({ control, disabled }: DimensionFormProps) => {
  const { t } = useTranslation();

  const { productId = '' } = useProduct();

  const { data: dimensions } = useGetDimensionTypes(productId);

  const { field, fieldState } = useController({
    name: 'dimensions',
    control,
  });

  const handleFieldChange = (value: string, id: number, index: number) => {
    const dimension = { id: id, value };

    if (field.value) {
      const inputtedField = field.value.find((v) => v?.id === id);

      if (inputtedField) {
        inputtedField.value = value;
        field.onChange(field.value);
        return;
      }

      field.value[index] = dimension;
      field.onChange(field.value);
    } else {
      field.onChange([dimension]);
    }
  };

  return (
    <>
      <Grid container spacing={2.5} item>
        {dimensions?.map((d, idx) => (
          <Grid item xs={3} key={d.id}>
            <FormDecimalField
              control={control}
              name={`${field.name}.${idx}.value`}
              value={field.value?.[idx]?.value || ''}
              label={toLengthUnit(d.name)}
              rules={{
                validate: {
                  max: checkMaxValue(1000, t),
                  greaterThanZero: (v) => {
                    if (!v) return;
                    return parseDecimal(v) <= 0
                      ? t('QC_V2.DEFECT.GREATER_THAN', { value: 0 })
                      : undefined;
                  },
                },
              }}
              onChange={(value) => {
                if (!value && field.value) {
                  const fields = field.value.map((f, fieldIdx) => {
                    if (fieldIdx === idx) return undefined;
                    return f;
                  });
                  field.onChange(fields);
                  return;
                }

                handleFieldChange(value, d.id, idx);
              }}
              disabled={disabled}
            />
          </Grid>
        ))}
      </Grid>

      <Box pl={2.5}>
        <ErrorMessage error={fieldState.error} />
      </Box>
    </>
  );
};

export default DimensionForm;
