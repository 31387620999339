import { Grid } from '@mui/material';
import { Control, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormDecimalField from '@/components/Common/Form/FormDecimalField';
import { toWeightUnit } from '@/helpers/unit';
import { DynamicFormValues } from '../DefectDetails';

interface WeightFormProps {
  control: Control<DynamicFormValues>;
  disabled: boolean;
  isRequired?: boolean;
}

const WeightForm = ({ control, disabled, isRequired }: WeightFormProps) => {
  const { t } = useTranslation();

  const { field: weight } = useController({
    control,
    name: 'weight',
  });

  return (
    <Grid item xs={12}>
      <FormDecimalField
        control={control}
        name="weight"
        label={toWeightUnit(t('QC_V2.DEFECT.WEIGHT'))}
        rules={{
          required: isRequired ? t('QC_V2.DEFECT.REQUIRED') : undefined,
        }}
        value={weight.value || ''}
        onChange={(value) => {
          weight.onChange(value);
        }}
        disabled={disabled}
      />
    </Grid>
  );
};

export default WeightForm;
