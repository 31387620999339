import { Env } from '@/environments';

const withStagingOnly = (Component: React.ComponentType<any>) => {
  const isProd = (process.env.NODE_ENV as Env) === 'production';

  const Wrapper = (props: any) => {
    if (isProd) return null;
    return <Component {...props} />;
  };

  return Wrapper;
};

export default withStagingOnly;
