import { FieldPath, FieldValues } from 'react-hook-form';
import { toDecimalUnit } from '@/helpers/unit';
import useDecimalField from '@/hooks/use-decimal-field';
import { FormField } from '@/types/interfaces/form';
import FormTextField from './FormTextField';

interface FormDecimalFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> extends FormField<TFieldValues, TName> {
  value: string;
  disabled?: boolean;
  isRequired?: boolean;
  onChange?: (v: string) => void;
}

const FormDecimalField = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  control,
  name,
  label,
  rules,
  value,
  onChange,
  disabled,
}: FormDecimalFieldProps<TFieldValues, TName>) => {
  const { onChange: handleChange } = useDecimalField(onChange);

  return (
    <FormTextField
      control={control}
      name={name}
      label={label}
      rules={rules}
      textFieldProps={{
        value: toDecimalUnit(value),
        onChange: handleChange,
        disabled: disabled,
      }}
    />
  );
};

export default FormDecimalField;
