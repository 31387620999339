import { useCallback, useEffect, useRef } from 'react';
import { SwiperRef } from 'swiper/react';

const usePhotoGallery = (selectedPhotoIndex?: number) => {
  const ref = useRef<SwiperRef>(null);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!ref.current) return;

      if (selectedPhotoIndex !== undefined) {
        scrollToPhoto(selectedPhotoIndex);
      }

      ref.current.swiper.on('keyPress', (swiper, keyCode) => {
        switch (parseInt(keyCode)) {
          case 38: // Up
          case 40: // Down
            scrollToPhoto(swiper.realIndex);
            break;
          default:
            break;
        }
      });
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [ref.current]);

  const scrollToPhoto = useCallback((index: number) => {
    const selectedPhotoBullet = document.getElementsByClassName(
      getPhotoBulletClass(index)
    )?.[0];

    if (!selectedPhotoBullet) return;

    selectedPhotoBullet.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  }, []);

  const getPhotoBulletClass = useCallback(
    (index: number) => `photo-bullet-${index}`,
    []
  );

  const zoomIn = useCallback(() => {
    if (ref.current) ref.current.swiper.zoom.in();
  }, [ref.current]);

  const zoomOut = useCallback(() => {
    if (ref.current) ref.current.swiper.zoom.out();
  }, [ref.current]);

  return {
    ref,
    zoomIn,
    zoomOut,
    getPhotoBulletClass,
  };
};

export default usePhotoGallery;
