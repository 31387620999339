import { Box, Tab, Tabs, tabsClasses } from '@mui/material';
import { styled } from '@mui/system';
import { RefObject, useEffect, useState } from 'react';
import { useModal } from '@/context/Modal.context';
import { buildImageUrl } from '@/helpers/image';

const Image = styled('img')(() => ({
  display: 'block',
  width: '9rem',
  height: '9rem',
  ':hover': { cursor: 'pointer' },
}));

const ProductImages = ({
  images,
  containerRef,
}: {
  images: string[];
  containerRef: RefObject<HTMLDivElement>;
}) => {
  const { setModalInfo } = useModal();

  const [value, setValue] = useState(0);

  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
    }
  }, [containerRef]);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleClick = (index: number) => {
    setModalInfo({
      modalType: 'IMAGE_GALLERY',
      modalInfo: {
        initialIndex: index,
      },
    });
  };

  return (
    <Box maxWidth={containerWidth}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        aria-label="visible arrows tabs"
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 },
          },
          [`& .${tabsClasses.flexContainer}`]: {
            gap: 2,
          },
          '& button': {
            padding: 0,
          },
        }}
      >
        {images.map((i, idx) => (
          <Tab
            key={idx}
            label={
              <Image
                data-testid="product_info_image"
                src={buildImageUrl({ path: i, quality: 75 })}
                key={i}
                alt={i}
                onClick={() => handleClick(idx)}
              />
            }
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default ProductImages;
