import { Stack } from '@mui/material';
import { MouseEvent, useEffect, useState } from 'react';
import {
  ControllerRenderProps,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form';
import {
  buildImageUrl,
  getPhotoInfo,
  getSVGClickCoords,
} from '@/helpers/image';
import { PHOTO_TYPE } from '@/types/interfaces/defect-details';
import MarkingPhoto from './MarkingPhoto';
import { SelectedPhotoType } from './PhotoForm';
import { DynamicFormValues } from '../DefectDetails';
import MarkingCircle from '../MarkingCircle';

const MAX_CIRCLE = 5;

interface DefectMarkingPhotoProps {
  field:
    | ControllerRenderProps<DynamicFormValues, 'pdpPhotos'>
    | ControllerRenderProps<DynamicFormValues, 'uploadedPhotos'>;
  photo: SelectedPhotoType;
  disabled: boolean;
  setValue: UseFormSetValue<DynamicFormValues>;
  getValues: UseFormGetValues<DynamicFormValues>;
}

const DefectMarkingPhoto = ({
  field,
  photo,
  disabled,
  setValue,
  getValues,
}: DefectMarkingPhotoProps) => {
  const [selectedCircleId, setSelectedCircleId] = useState('');

  const [circles, setCircles] = useState<JSX.Element[]>([]);

  const fieldValue = field.value?.length
    ? field.value.find((v) => v?.id === photo.index)
    : undefined;

  /*
    Paint existing circles for clicked photo
  */
  useEffect(() => {
    setSelectedCircleId('');

    if (!fieldValue?.annotations?.length) {
      setCircles([]);
    } else {
      const prevCircles = fieldValue.annotations.map((a, idx) => {
        const id = new window.Date().getTime() + idx;
        return (
          <MarkingCircle
            key={id}
            id={id.toString()}
            x={a.x}
            y={a.y}
            onClick={setSelectedCircleId}
          />
        );
      });

      if (prevCircles.length) {
        setCircles(prevCircles);
      }
    }
  }, [photo]);

  /*
    Remove selected circle
  */
  useEffect(() => {
    if (disabled) return;
    if (selectedCircleId) {
      const allCircles = circles.filter((c) => c.props.id !== selectedCircleId);

      const coords = allCircles.map((c) => ({ x: c.props.x, y: c.props.y }));

      // Update annotations
      if (fieldValue && fieldValue.annotations?.length) {
        fieldValue.annotations = coords;
        setValue(field.name, field.value);
      }

      // Update inner states
      setCircles(allCircles);
      setSelectedCircleId('');
    }
  }, [selectedCircleId, circles]);

  const resetOtherPhotoData = (currentPhotoIndex: number) => {
    const formPhotos = getValues(field.name);

    formPhotos?.forEach((v) => {
      if (v.id !== currentPhotoIndex) {
        v.checked = false;
        v.annotations = undefined;
      }
    });

    setValue(field.name, formPhotos);
  };

  const updateFormValue = (annotation: { x: number; y: number }) => {
    let formPhotos = getValues(field.name);

    formPhotos = formPhotos ? formPhotos.filter((v) => !!v) : undefined;

    const photoData = {
      id: photo.index,
      annotations: [annotation],
      checked: true,
      path: photo.path,
      type: photo.type,
    };

    // Form photos is empty
    if (!formPhotos || !formPhotos.length) {
      setValue(field.name, [photoData]);
      return;
    }

    const fieldValue = formPhotos.find((v) => v.id === photo.index);

    // Marking for new item in the list
    if (!fieldValue) {
      setValue(field.name, [...formPhotos, photoData]);
      return;
    }

    fieldValue.id = photoData.id;
    fieldValue.checked = photoData.checked;
    fieldValue.path = photoData.path;
    fieldValue.type = photoData.type;

    if (fieldValue.annotations?.length) {
      fieldValue.annotations.push(annotation);
    } else {
      fieldValue.annotations = [annotation];
    }

    setValue(field.name, formPhotos);
  };

  const addCircle = (e: MouseEvent<SVGSVGElement>) => {
    if (circles.length >= MAX_CIRCLE) return;

    if (disabled) return;

    const [x, y] = getSVGClickCoords(e);

    const annotation = { x, y };

    updateFormValue(annotation);

    // TODO: OPS-4650 - Will remove this when QC report V2 ready (support multi defect photos)
    resetOtherPhotoData(photo.index);

    const id = new Date().getTime().toString();

    const newCircle = (
      <MarkingCircle
        key={id}
        id={id}
        x={x}
        y={y}
        onClick={setSelectedCircleId}
      />
    );

    const allCircles = [...circles, newCircle];

    setCircles(allCircles);
  };

  const getPhotoUrl = () => {
    const { imgType, imgName } = getPhotoInfo(photo.path);
    return buildImageUrl({
      path: imgName,
      type: imgType as PHOTO_TYPE,
      quality: 90,
    });
  };

  return (
    <Stack sx={{ mt: 2.5 }}>
      <MarkingPhoto
        addCircle={addCircle}
        photoUrl={getPhotoUrl()}
        circles={circles}
      />
    </Stack>
  );
};

export default DefectMarkingPhoto;
