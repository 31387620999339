import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import * as React from 'react';

import { useTranslation } from 'react-i18next';
import CheckPointList from '@/components/CheckPointList/CheckPointList';
import DefectList from '../DefectList';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { value, index, children, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      style={{
        minHeight: 'calc(100vh - 19rem)',
      }}
      {...other}
    >
      {children}
    </div>
  );
}

export default function ScrollableTabs() {
  const { t } = useTranslation();

  const [value, setValue] = React.useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={(theme) => ({
        bgcolor: theme.palette.background.default,
        mt: 3,
        px: 2,
        pr: 0,
      })}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        aria-label="checkpointsTabs"
      >
        <Tab label={t('QC_V2.MAIN.CHECKING_LIST')} />
        <Tab label={t('QC_V2.MAIN.DEFECT_LIST')} />
      </Tabs>
      <Box
        sx={{
          height: 'calc(100vh - 19rem)',
          overflow: 'scroll',
          scrollbarWidth: 'thin',
        }}
      >
        <TabPanel value={value} index={0}>
          <CheckPointList />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <DefectList />
        </TabPanel>
      </Box>
    </Box>
  );
}
