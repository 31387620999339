import { Stack, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from '@/context/Modal.context';
import { useQualityControlTool } from '@/context/Qualitycontrol.context';
import { useAdditionalItemList } from '@/hooks/use-checkpoints';
import { AdditionalItemType } from '@/types/AdditionalItem';
import Item from './Item';

const COLUMNS = 4;

export default function AdditionalItemsList() {
  const { additionalItemList, isLoadingAddtionalItems } =
    useAdditionalItemList();
  const { additionalItems } = useQualityControlTool();
  const [checkedItems, setCheckedItems] =
    useState<AdditionalItemType[]>(additionalItems);
  const { setModalData } = useModal();
  const { t } = useTranslation();

  const onItemCheck = (checked: boolean, item: AdditionalItemType) => {
    if (checked) {
      setCheckedItems([...checkedItems, item]);
      setModalData([...checkedItems, item]);
    } else {
      setCheckedItems(
        checkedItems.filter((checkedItem) => checkedItem.id !== item.id)
      );
      setModalData(
        checkedItems.filter((checkedItem) => checkedItem.id !== item.id)
      );
    }
  };

  const itemPerColumn = additionalItemList
    ? Math.ceil(additionalItemList.length / COLUMNS)
    : 0;

  const data = useMemo(() => {
    if (!itemPerColumn || !additionalItemList) return undefined;

    const clonedItems = [...additionalItemList].sort(
      (a, b) => -b.name.localeCompare(a.name)
    );

    return Array.from(Array(COLUMNS)).map(() =>
      clonedItems.splice(0, itemPerColumn)
    );
  }, [itemPerColumn, additionalItemList]);

  if (isLoadingAddtionalItems) {
    return <div>{t('QC_V2.COMMON.LOADING')}</div>;
  }

  return (
    <Stack flexDirection="row" justifyContent="space-between">
      {data?.length ? (
        data.map((d, idx) => (
          <Stack flex={`1 1 ${100 / COLUMNS}%`} key={idx}>
            {d.map((item) => (
              <Item
                key={item.id}
                item={item}
                onCheckItem={onItemCheck}
                checked={checkedItems.some((i) => i.id === item.id)}
              />
            ))}
          </Stack>
        ))
      ) : (
        <Typography>{t('QC_V2.NO_DATA')}</Typography>
      )}
    </Stack>
  );
}
