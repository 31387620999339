import { Stack } from '@mui/material';
import { Zoom, Pagination, Keyboard } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/zoom';
import 'swiper/css/pagination';
import { useModal } from '@/context/Modal.context';
import { buildImageUrl } from '@/helpers/image';
import usePhotoGallery from '@/hooks/use-photo-gallery';
import { useProduct } from '@/hooks/use-product';
import { QcInfoType } from '@/types/QcInfo';
import './ImageGallery.scss';
import GalleryModal from '../Modal/GalleryModal';
import { ProductInfo } from '../ProductInfo/ProductInfo';

export default function ImageGallery() {
  const { setModalInfo, modal } = useModal();
  const { qcData } = useProduct();
  const qcInfo = qcData as QcInfoType;
  const productImgs = (qcInfo.product?.images || []).map((img) =>
    buildImageUrl({ path: img, quality: 90 })
  );

  const {
    modalInfo: { initialIndex },
  } = modal;

  const { ref, zoomIn, zoomOut, getPhotoBulletClass } =
    usePhotoGallery(initialIndex);

  return (
    <GalleryModal
      onClose={() =>
        setModalInfo({
          modalType: '',
          modalInfo: {},
        })
      }
      zoomIn={zoomIn}
      zoomOut={zoomOut}
    >
      <Stack
        sx={{
          flex: '0 1 35%',
          p: 3,
          overflowY: 'scroll',
          scrollbarWidth: 'thin',
        }}
      >
        <ProductInfo whiteText />
      </Stack>

      <Stack sx={{ flex: '1 1 auto' }}>
        <Swiper
          ref={ref}
          className="mySwiper"
          initialSlide={initialIndex || 0}
          slidesPerView={1}
          direction="vertical"
          pagination={{
            clickable: true,
            renderBullet: (index, className) => {
              return `<img class="${className} ${getPhotoBulletClass(index)}" src="${productImgs[index]}" />`;
            },
          }}
          zoom={true}
          keyboard={{
            enabled: true,
            pageUpDown: true,
          }}
          modules={[Zoom, Pagination, Keyboard]}
        >
          {productImgs.map((img, index) => {
            return (
              <SwiperSlide key={index}>
                <div className="swiper-zoom-container">
                  <img src={img} alt={img} loading="lazy" />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Stack>
    </GalleryModal>
  );
}
