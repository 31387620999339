import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { Button, Stack } from '@mui/material';
import Cookies from 'cookies-ts';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import LoadingBox from '@/components/DefectDetailModal/LoadingBox';
import PhotoItem from '@/components/DefectDetailModal/PhotoForms/PhotoItem';
import { buildImageUrl, getPhotoInfo } from '@/helpers/image';
import { useUploadImage } from '@/hooks/defect-details/use-upload-image';
import { useQuery } from '@/hooks/use-url';
import { PHOTO_TYPE } from '@/types/interfaces/defect-details';

const TakePhoto: React.FC = () => {
  const takePhotoRef = useRef<HTMLInputElement>(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const [photos, setPhotos] = useState<string[]>([]);

  const urlParams = useQuery();

  const { isPending, mutateAsync } = useUploadImage('defect');

  const cookies = new Cookies();

  const token = urlParams.get('token');
  const uuid = urlParams.get('uuid');

  useEffect(() => {
    if (token && uuid) {
      cookies.set('id_token_2', token);

      if (searchParams.has('token')) {
        searchParams.delete('token');
        setSearchParams(searchParams);
      }
    }
  }, [token, uuid]);

  const handleUploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    const chosenFile = e.target.files?.[0];

    if (!chosenFile || !uuid) return;

    const file = new File(
      [chosenFile],
      `${Date.now()}-${(chosenFile as File).name}`,
      {
        type: chosenFile.type,
      }
    );

    const result = await mutateAsync({
      uuid,
      file,
      token: cookies.get('id_token_2'),
    });

    if (result) setPhotos([...photos, result.imageUrl]);
  };

  return (
    <Stack position="relative" height="100vh">
      <Stack position="absolute" bottom={100} margin="auto" left={0} right={0}>
        <input
          ref={takePhotoRef}
          type="file"
          accept="image/*"
          capture="environment"
          style={{ visibility: 'hidden' }}
          onChange={handleUploadFile}
        />
        <Button id="test" onClick={() => takePhotoRef.current?.click()}>
          <Stack flexDirection="row" gap={1} alignItems="center">
            <CameraAltIcon />
            Take photo
          </Stack>
        </Button>
      </Stack>

      <Stack flexWrap="wrap" flexDirection="row" gap={1} p={1}>
        {photos.map((p, idx) => {
          const { imgType, imgName } = getPhotoInfo(p);

          const url = buildImageUrl({
            path: imgName,
            type: imgType as PHOTO_TYPE,
          });

          return <PhotoItem key={idx} imgUrl={url} />;
        })}

        {isPending && <LoadingBox />}
      </Stack>
    </Stack>
  );
};

export default TakePhoto;
