import { ButtonProps, Button as MuiButton } from '@mui/material';
import { BOX_SIZE } from './PhotoForms/PhotoForm';

export interface CustomButtonProps {
  name: string;
  icon?: JSX.Element;
  isActive?: boolean;
  props?: ButtonProps;
}

const Button = ({ name, icon, isActive, props }: CustomButtonProps) => {
  return (
    <MuiButton
      variant="outlined"
      component="span"
      sx={(theme) => ({
        width: BOX_SIZE,
        height: BOX_SIZE,
        display: 'flex',
        flexDirection: 'column',
        textTransform: 'capitalize',
        fontSize: 12,
        p: 0,
        borderStyle: isActive ? 'double' : 'dashed',
        borderColor: isActive
          ? theme.palette.borderColor.dark
          : theme.palette.borderColor.light,
        color: 'text.primary',
      })}
      {...props}
    >
      {icon}
      {name}
    </MuiButton>
  );
};

export default Button;
